import { jsx as _jsx } from "react/jsx-runtime";
import { IndicatorService } from 'src/services/IndicatorService';
import { INDICATOR_DATA_BASE_URL, handleAddQuery } from '@/utils';
import { RequestWrapper } from 'src/components/win-wrappers/request-wrapper';
import { WinChart } from 'src/components/win-chart';
export const ChartContent = ({ configData, orgLevelVal, winChartType, reserveValueWithLabelType, }) => {
    return (_jsx(RequestWrapper, { requestData: {
            url: handleAddQuery(INDICATOR_DATA_BASE_URL, {
                id: configData.id,
                dataAccVal: configData.dataAccCode,
                orgLevelVal,
            }),
        }, service: IndicatorService.getDataInfoList, children: (data) => {
            return (_jsx(WinChart, { sort: configData.commonConfig.sort, yStart: configData.commonConfig.yStart, color: configData.commonConfig.color, reserveValueWithLabelType: reserveValueWithLabelType, xAxisLabelLength: configData.commonConfig.xAxisLabelLength, xAxisLabelRotate: configData.commonConfig.xAxisLabelRotate, data: data.list0, extraData: data.list1, chartType: winChartType, style: { height: 'calc(100% - 20px)' } }));
        } }));
};
