import { newRequest } from '@/api/request';
import { handleAddQuery, handleResult } from 'src/utils';
export class IndicatorService {
    /**
     * 指标服务通用接口
     * @param request
     * @returns
     */
    static async getConfigInfo(request) {
        const resp = handleResult(await newRequest({
            url: request?.url,
            method: 'GET',
        }));
        return resp;
    }
    /**
     * 指标服务通用接口 List
     * @param request
     * @returns
     */
    static async getDataInfoList(request) {
        const resp = handleResult(await newRequest({
            url: request?.url,
            method: 'GET',
            withCredentials: true,
        }));
        return resp;
    }
    /**
     * 指标服务通用接口
     * @param request
     * @returns
     */
    static async getDataInfo(request) {
        const resp = handleResult(await newRequest({
            url: request.url,
            method: 'GET',
        }));
        return resp;
    }
    /**
     * 获取指标单位列表
     * @returns
     */
    static async getUnitList() {
        const resp = handleResult(await newRequest({
            url: '/config/card/unit',
        }));
        return resp;
    }
    /**
     * 获取指标范围列表
     * @returns
     */
    static async getRangeList() {
        const resp = handleResult(await newRequest({
            url: '/show/head/range',
            method: 'GET',
        }));
        return resp;
    }
    /**
     * 获取指标分类列表
     * @returns
     */
    static async getClassifyList() {
        const resp = handleResult(await newRequest({
            url: '/show/head/classify',
            method: 'GET',
        }));
        return resp;
    }
    /**
     * 获取指标类别列表
     * @returns
     */
    static async getTypeList() {
        const resp = handleResult(await newRequest({
            url: '/show/head/type',
            method: 'GET',
        }));
        return resp;
    }
    /**
     * 获取指标标签列表
     * @returns
     */
    static async getLabelList() {
        const resp = handleResult(await newRequest({
            url: '/show/head/label',
            method: 'GET',
        }));
        return resp;
    }
    /**
     * 获取指标标签列表
     * @returns
     */
    static async getDicList(name) {
        const resp = handleResult(await newRequest({
            url: `/show/head/dic?name=${name}`,
            method: 'GET',
        }));
        return resp;
    }
    /**
     * 查询指标中心列表
     * @returns
     */
    static async getCardList(data) {
        const resp = handleResult(await newRequest({
            url: '/show/card/show',
            method: 'POST',
            data,
        }));
        return resp;
    }
    /**
     * 查询指标卡片数量
     * @param data
     * @returns
     */
    static async getCountList(data) {
        const resp = handleResult(await newRequest({
            url: '/show/card/number',
            method: 'POST',
            data,
        }));
        return resp;
    }
    /**
     * 更新订阅状态
     * @param subId
     * @returns
     */
    static async updateFollow(subId) {
        const resp = handleResult(await newRequest({
            url: '/show/update/follow',
            method: 'POST',
            data: {
                subId,
            },
        }));
        return resp;
    }
    /**
     * 获取单个卡片查询列表
     * @param subId
     * @returns
     */
    static async getCardQueryList(subId) {
        const resp = handleResult(await newRequest({
            url: '/show/card/query',
            method: 'POST',
            data: {
                subId,
            },
        }));
        return resp;
    }
    /**
     * 查询指标信息
     * @param subId
     * @returns
     */
    static async queryCard(subId, type) {
        const { value, expectValue, ratio, selectLabel } = handleResult(await newRequest({
            url: '/show/card/single',
            method: 'POST',
            data: {
                subId,
                type,
            },
        }));
        return {
            value,
            expectValue,
            ratio,
            selectLabel,
        };
    }
    /**
     * 指标中心列表逐级查询
     * @param data
     * @returns
     */
    static async getTableDataList(data) {
        const resp = handleResult(await newRequest({
            url: '/show/list/show',
            method: 'POST',
            data,
        }));
        return resp;
    }
    /**
     * 查询指标详情
     * @param subId
     * @returns
     */
    static async getDetails({ subId, indicatorDate }) {
        const resp = handleResult(await newRequest({
            url: '/show/details',
            method: 'POST',
            data: {
                subId,
                indicatorDate,
            },
        }));
        return resp;
    }
    /**
     * 查询下钻指标详情
     * @param subId
     * @returns
     */
    static async getDownDetails({ originId, subId, name, type, indicatorDate, }) {
        const resp = handleResult(await newRequest({
            url: '/show/details/down',
            method: 'POST',
            data: {
                originId,
                subId,
                name,
                type,
                indicatorDate,
            },
        }));
        return resp;
    }
    /**
     * 查询指标详情时间下拉数据源
     * @param subId
     * @returns
     */
    static async getDateList(subId) {
        const resp = handleResult(await newRequest({
            url: '/show/details/date',
            method: 'POST',
            data: {
                subId,
            },
        }));
        return resp;
    }
    /**
     * 查询指标详情趋势图
     * @param subId
     * @returns
     */
    static async getDetailsTrend(subId, name) {
        const resp = handleResult(await newRequest({
            url: '/show/details/trend',
            method: 'POST',
            data: {
                subId,
                name,
            },
        }));
        return resp;
    }
    /**
     * 查询指标实例列表
     * @param timeSpan
     * @param orgLevel
     * @returns
     */
    static async queryIndicatorList(timeSpan, orgLevel) {
        const resp = handleResult(await newRequest({
            url: handleAddQuery('/cone/queryIndicatorSubs', {
                timeSpan,
                orgLevel,
            }),
            method: 'GET',
        }));
        return resp.tableData.map((item) => ({
            label: item.instName,
            value: item.id,
        }));
    }
    /**
     * 查询指标维度列表
     * @param orgLevel
     * @returns
     */
    static async getOrgLevelList(orgLevel) {
        const resp = handleResult(await newRequest({
            url: '/indicator/permission/orgLevel',
            params: {
                orgLevel,
            },
        }));
        return resp;
    }
}
