import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import $i18n from '@alife/panda-i18n';
import { styled } from 'styled-components';
import { getHandleOpenLink, checkEntityArr } from '@/utils';
import { NoContent } from 'src/components/none-content';
import { CnBox } from '@alife/cn-ui';
import { IconBox } from '@/components/icon';
export const MatterList = ({ data = [], showHandle }) => {
    return (_jsx(ListWrapper, { children: checkEntityArr(data) ? (data.map((item, index) => (_jsxs(DescriptionWrapper, { children: [_jsxs(CnBox, { direction: 'row', spacing: 8, children: [_jsx(IconBox, { size: 24, src: ['TERMINATED', 'COMPLETED'].includes(item.status)
                                ? DONE
                                : PROCESSING }), _jsxs("div", { children: [_jsx("div", { "data-title": true, onClick: getHandleOpenLink(item.url), style: {
                                        ...(item.title.startsWith('【') && {
                                            transform: 'translateX(-8px)',
                                        }),
                                    }, children: item.title }), _jsxs("div", { "data-extra": true, children: [_jsx("span", { children: $i18n.get({
                                                id: 'InitiatoritemApplicantName',
                                                dm: '发起人：{itemApplicantName}',
                                            }, { itemApplicantName: item.applicantName }) }), _jsx("span", { children: item.applyTime }), !!item.handlerName && (_jsx("span", { children: $i18n.get({
                                                id: 'CurrentApproveritemHandlerName',
                                                dm: '当前审批人：{itemHandlerName}',
                                            }, { itemHandlerName: item.handlerName }) }))] })] })] }), showHandle && (_jsx(HandleWrapper, { onClick: getHandleOpenLink(item.url), children: "\uD83D\uDC49\u53BB\u5904\u7406" }))] }, index)))) : (_jsx(NoContent, { message: $i18n.get({ id: 'TERM.NoData', dm: '暂无数据' }), style: { transform: 'translateY(120px)' } })) }));
};
const ListWrapper = styled.div `
  display: grid;
  grid: auto-flow 64px / 1fr;
  gap: 0;
  line-height: 1;
  font-size: 14px;
  overflow: hidden;
  height: 310px;
  margin-bottom: 12px;
  place-items: center stretch;
`;
const DescriptionWrapper = styled.div `
  height: 100%;
  border-top: solid #eee 1px;
  display: grid;
  grid: auto-flow / 1fr 70px;
  place-items: center stretch;

  [data-title] {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    color: #272f3d;
    text-overflow: ellipsis;
  }

  [data-extra] {
    font-size: 12px;
    color: #5d677a;
    line-height: 1;
    span {
      margin-right: 12px;
    }
  }
`;
const HandleWrapper = styled.div `
  cursor: pointer;
  color: #272f3d;
  font-size: 12px;
  height: 22px;
  text-align: center;
  line-height: 21px;
  border: solid #ddd 1px;
  /* width: 80px; */
  border-radius: 12px;
`;
const PROCESSING = 'https://img.alicdn.com/imgextra/i3/O1CN01HvOfeP1XOCe517Amr_!!6000000002913-55-tps-31-29.svg';
const DONE = 'https://img.alicdn.com/imgextra/i3/O1CN01jr6Aiq1EIjDAqHXkZ_!!6000000000329-55-tps-30-28.svg';
