import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CnBox } from '@alife/cn-ui';
import { Fragment } from 'react';
import { HoverView } from 'src/components/hover-view';
import { RequestWrapper } from 'src/components/win-wrappers/request-wrapper';
import { WorkbenchService } from 'src/services/WorkbenchService';
import { styled } from 'styled-components';
export const WinRankContent = () => {
    return (_jsx(RequestWrapper, { service: WorkbenchService.queryWinRankList, children: (data) => {
            return (_jsx(Wrapper, { children: data.map((item, index) => (_jsxs("div", { children: [_jsx("div", { "data-title": true, children: item.title }), _jsx("div", { "data-content": true, children: item.list?.map((info, i) => {
                                const isTopFive = i < 5;
                                const getMarginStyle = () => {
                                    if (i === 4) {
                                        return {
                                            marginBottom: 4,
                                        };
                                    }
                                };
                                return (_jsx(Fragment, { children: index === 0 ? (_jsx(Mark, { style: getMarginStyle(), children: isTopFive ? '❤️' : '💪' })) : (_jsxs(InfoDecoration, { style: {
                                            backgroundColor: isTopFive
                                                ? 'rgba(250,107,105,0.1)'
                                                : 'rgba(177,185,199,0.2)',
                                            ...getMarginStyle(),
                                        }, children: [_jsx("div", { "data-label": true, children: _jsx(HoverView, { content: info.label }) }), _jsx("span", { children: info.value })] })) }, info.label + info.children));
                            }) })] }, item.title))) }));
        } }));
};
const Wrapper = styled.div `
  display: grid;
  grid: auto-flow / 36px repeat(4, 1fr);
  gap: 8px;
  overflow: auto;

  [data-title] {
    font-weight: 500;
    font-size: 12px;
    color: #394252;
    line-height: 1;
    margin-bottom: 16px;
    display: grid;
    place-items: center;
  }

  [data-content] {
    display: grid;
    grid: auto-flow 24px / 1fr;
    gap: 12px;
  }
`;
const Mark = styled.div `
  display: grid;
  place-items: center;
`;
const InfoDecoration = styled(CnBox).attrs({
    direction: 'row',
    justify: 'space-between',
    align: 'center',
}) `
  border-radius: 500px;
  font-size: 12px;
  color: #272f3d;
  padding: 0 10px;

  [data-label] {
    width: 80px;
  }
`;
