import { WinChartType } from 'src/components/win-chart';
import { MatterType, WinCardChartType, } from 'src/types';
/**
 * 时间范围列表
 */
export const timeRangeList = [
    { label: '天', value: 'DAY' },
    { label: '月', value: 'MONTH' },
    { label: '季度', value: 'QUARTER' },
    { label: '年', value: 'YEAR' },
    { label: '财季', value: 'FQUARTER' },
    { label: '财年', value: 'FYEAR' },
    { label: '周_列日', value: 'WEEK_LIEGE' },
    { label: '月底及最新', value: 'MONTH_LATEST' },
    { label: '季度底及最新', value: 'QUARTER_LATEST' },
];
/**
 * 结构化属性
 */
export const orgLevelList = [
    { label: '维度项', value: 'LEVEL_1' },
    { label: '业务线', value: 'LEVEL_2' },
    { label: '区域', value: 'LEVEL_3' },
    { label: '项目', value: 'LEVEL_4' },
    { label: '园区', value: 'LEVEL_5' },
];
/**
 * 结构化属性
 */
export const labelFieldList = [
    // { label: '地网级指标', value: 'LEVEL_1' },
    // { label: '业务线指标', value: 'LEVEL_2' },
    // { label: '区区域级指标', value: 'LEVEL_3' },
    // { label: '项目级指标', value: 'LEVEL_4' },
    // { label: '园区级指标', value: 'LEVEL_5' },
    { label: '指标项', value: 'label' },
    { label: '日期', value: 'date' },
];
/**
 * 卡片状态列表
 */
export const cardStatusList = [
    { label: '启用', value: 'ONLINE' },
    { label: '禁用', value: 'OFFLINE' },
];
/**
 * 单位列表
 */
export const unitList = [
    { label: '平米', value: 'smeter' },
    { label: '元', value: 'yuan' },
    { label: '%', value: 'percent' },
    { label: '个', value: 'number' },
    { label: '分', value: 'score' },
    { label: '万元', value: 'wan_yuan' },
    { label: '百万', value: 'million' },
    { label: '亿元', value: 'billion' },
    { label: '万方', value: 'wan_square' },
];
/**
 * 卡片模版列表
 */
export const chartTypeList = [
    {
        label: '',
        value: WinCardChartType.INDICATOR,
    },
];
export const searchResTypeMap = new Map()
    .set('ALL', {
    label: '全部',
})
    .set('CARD', {
    label: '卡片',
})
    .set('MENU', {
    label: '菜单',
})
    .set('REPORT', {
    label: '报表',
})
    .set('CONTRACT', {
    label: '招商合同',
})
    .set('BUSINESS_APPLY', {
    label: '商务条件申请',
})
    .set('SECURITY', {
    label: '安全隐患',
})
    .set('RISK', {
    label: '风险',
})
    .set('PARK_REFORM', {
    label: '园区改造',
});
export const matterTitleList = [
    { label: '待办清单', value: MatterType.PENDING },
    { label: '我发出的', value: MatterType.CREATE },
    { label: '已办事项', value: MatterType.COMPLETE },
];
export const showTypeList = [
    { label: '菜鸟', value: 'CN' },
    { label: 'WLCC', value: 'wlcc' },
];
export const rentTypeList = [
    { label: '综合', value: '综合' },
    { label: '仓', value: '仓' },
    { label: '配', value: '配' },
];
/**
 * 卡片类型
 */
export const indicatorChartTypeList = [
    {
        label: '单指标卡片',
        value: WinCardChartType.INDICATOR,
    },
    {
        label: '指标趋势卡片',
        value: WinCardChartType.INDICATOR_AREA,
    },
    {
        label: '面积图卡片',
        value: WinCardChartType.AREA_CHART,
    },
    {
        label: '折线图卡片',
        value: WinCardChartType.LINE_CHART,
    },
    {
        label: '双轴图卡片',
        value: WinCardChartType.DUAL_LINE_BAR_CHART,
    },
    {
        label: '柱状图卡片',
        value: WinCardChartType.COLUMN_CHART,
    },
    {
        label: '堆叠柱状图卡片',
        value: WinCardChartType.STACK_COLUMN_CHART,
    },
    {
        label: '条形图卡片',
        value: WinCardChartType.BAR_CHART,
    },
    {
        label: '漏斗图卡片',
        value: WinCardChartType.FUNNEL_CHART,
    },
    {
        label: '饼图卡片',
        value: WinCardChartType.PIE_CHART,
    },
    {
        label: '饼图卡片（指标项展示）',
        value: WinCardChartType.PIE_CHART_RESERVE,
    },
    {
        label: '环图卡片',
        value: WinCardChartType.CYCLE_CHART,
    },
    {
        label: '雷达图卡片',
        value: WinCardChartType.RADAR_CHART,
    },
    {
        label: '雷达图卡片（指标项展示）',
        value: WinCardChartType.RADAR_CHART_RESERVE,
    },
];
/**
 * 卡片类别信息映射
 */
export const winCardChartTypeMap = new Map();
winCardChartTypeMap.set(WinCardChartType.CUSTOM_CARD, {
    templateUrl: 'https://img.alicdn.com/imgextra/i3/O1CN011Bi11a1YzaZCM32a3_!!6000000003130-2-tps-1376-800.png',
    type: 'Custom',
    sizeList: [
        { label: '1行 × 1列', value: '1,1' },
        { label: '1行 × 2列', value: '1,2' },
        { label: '2行 × 1列', value: '2,1' },
        { label: '2行 × 2列', value: '2,2' },
        { label: '2行 × 4列', value: '2,4' },
        { label: '4行 × 4列', value: '4,4' },
    ],
});
winCardChartTypeMap.set(WinCardChartType.DUAL_LINE_BAR_CHART, {
    templateUrl: 'https://img.alicdn.com/imgextra/i1/O1CN01oW8CLa1lrsvlcyBHV_!!6000000004873-2-tps-680-560.png',
    type: 'Chart',
    sizeList: [
        { label: '2行 × 2列', value: '2,2' },
        { label: '2行 × 4列', value: '2,4' },
        { label: '2行 × 6列', value: '2,6' },
    ],
});
winCardChartTypeMap.set(WinCardChartType.INDICATOR, {
    templateUrl: 'https://img.alicdn.com/imgextra/i4/O1CN01FnE5KR1EV5typW3ya_!!6000000000356-2-tps-332-272.png',
    type: 'Indicator',
    sizeList: [{ label: '1行 × 1列', value: '1,1' }],
});
winCardChartTypeMap.set(WinCardChartType.INDICATOR_AREA, {
    templateUrl: 'https://img.alicdn.com/imgextra/i3/O1CN01IiUDn81hQctefyf11_!!6000000004272-2-tps-680-272.png',
    type: 'IndicatorChart',
    sizeList: [{ label: '1行 × 2列', value: '1,2' }],
});
winCardChartTypeMap.set(WinCardChartType.AREA_CHART, {
    templateUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01I6IkaB1iE5fcXcIXU_!!6000000004380-2-tps-680-560.png',
    type: 'Chart',
    sizeList: [
        { label: '2行 × 2列', value: '2,2' },
        { label: '2行 × 4列', value: '2,4' },
    ],
    winChartType: WinChartType.AREA,
});
winCardChartTypeMap.set(WinCardChartType.LINE_CHART, {
    templateUrl: 'https://img.alicdn.com/imgextra/i2/O1CN0181hNue1nRRIIpXPUf_!!6000000005086-2-tps-1376-560.png',
    type: 'Chart',
    sizeList: [
        { label: '2行 × 2列', value: '2,2' },
        { label: '2行 × 4列', value: '2,4' },
        { label: '2行 × 6列', value: '2,6' },
    ],
    winChartType: WinChartType.LINE,
});
winCardChartTypeMap.set(WinCardChartType.COLUMN_CHART, {
    templateUrl: 'https://img.alicdn.com/imgextra/i4/O1CN01iIxIfy1jYWxlzTSGb_!!6000000004560-2-tps-680-560.png',
    type: 'Chart',
    sizeList: [
        { label: '2行 × 2列', value: '2,2' },
        { label: '2行 × 4列', value: '2,4' },
        { label: '2行 × 6列', value: '2,6' },
    ],
});
winCardChartTypeMap.set(WinCardChartType.STACK_COLUMN_CHART, {
    templateUrl: 'https://img.alicdn.com/imgextra/i3/O1CN01G9tHC81ImHE4RVLbC_!!6000000000935-2-tps-680-560.png',
    type: 'Chart',
    sizeList: [
        { label: '2行 × 2列', value: '2,2' },
        { label: '2行 × 4列', value: '2,4' },
        { label: '2行 × 6列', value: '2,6' },
    ],
});
winCardChartTypeMap.set(WinCardChartType.BAR_CHART, {
    templateUrl: 'https://img.alicdn.com/imgextra/i1/O1CN01fS4z2F1lBI66teufq_!!6000000004780-2-tps-680-560.png',
    type: 'Chart',
    sizeList: [
        { label: '2行 × 2列', value: '2,2' },
        { label: '2行 × 4列', value: '2,4' },
    ],
});
winCardChartTypeMap.set(WinCardChartType.PIE_CHART, {
    templateUrl: 'https://img.alicdn.com/imgextra/i4/O1CN01aG0pam21xPZ0KAldD_!!6000000007051-2-tps-680-560.png',
    type: 'Chart',
    sizeList: [{ label: '2行 × 2列', value: '2,2' }],
});
winCardChartTypeMap.set(WinCardChartType.PIE_CHART_RESERVE, {
    templateUrl: 'https://img.alicdn.com/imgextra/i4/O1CN01aG0pam21xPZ0KAldD_!!6000000007051-2-tps-680-560.png',
    type: 'Chart',
    sizeList: [{ label: '2行 × 2列', value: '2,2' }],
});
winCardChartTypeMap.set(WinCardChartType.CYCLE_CHART, {
    templateUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01ZkptA11f6M8UT8XGw_!!6000000003957-2-tps-680-560.png',
    type: 'Chart',
    sizeList: [{ label: '2行 × 2列', value: '2,2' }],
});
winCardChartTypeMap.set(WinCardChartType.FUNNEL_CHART, {
    templateUrl: 'https://img.alicdn.com/imgextra/i1/O1CN01Cmqcqi1HWsHscvuPN_!!6000000000766-2-tps-680-560.png',
    type: 'Chart',
    sizeList: [{ label: '2行 × 2列', value: '2,2' }],
});
winCardChartTypeMap.set(WinCardChartType.RADAR_CHART, {
    templateUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01Pk7XU01hztOlrBMQe_!!6000000004349-2-tps-680-560.png',
    type: 'Chart',
    sizeList: [{ label: '2行 × 2列', value: '2,2' }],
});
winCardChartTypeMap.set(WinCardChartType.RADAR_CHART_RESERVE, {
    templateUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01Pk7XU01hztOlrBMQe_!!6000000004349-2-tps-680-560.png',
    type: 'Chart',
    sizeList: [{ label: '2行 × 2列', value: '2,2' }],
});
export const sortList = [
    { label: '升序', value: 'asc' },
    { label: '降序', value: 'desc' },
];
export const msgBizTypeMap = new Map()
    .set('INDICATOR_CENTER', {
    iconType: 'icon-zhibiaozhongxin1',
    color: '#347FFF',
})
    .set('NEWS_NOTICE', {
    iconType: 'icon-xinxiansudi1',
    color: '#00C267',
})
    .set('EARTH_SCREEN', {
    iconType: 'icon-xinxiansudi1',
    color: '#00C267',
})
    .set('GOOD_HABITS', {
    iconType: 'icon-v-function',
    color: '#347FFF',
})
    .set('OPERATE_SCORE', {
    iconType: 'icon-jingyingpaihang',
    color: '#FFA101',
})
    .set('QUARTERLY_SCORE', {
    iconType: 'icon-jidupingfen',
    color: '#FFA101',
})
    .set('RISK', {
    iconType: 'icon-fengxiandapan1',
    color: '#F15451',
});
