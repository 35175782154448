import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
 * @author wb-zrq481890
 * @version 1.0.0
 * @Description index
 * @create 2023/7/4
 */
import React from 'react';
import { Box, CnTextEllipsis } from '@alife/cn-ui';
import styled from 'styled-components';
const IconBox = styled.img `
  width: 40px;
`;
const ItemBox = styled.div `
  margin-bottom: 20px;
`;
const Item = (props) => {
    const { iconUrl, dataSource = {}, itemField = [] } = props;
    return (_jsx(ItemBox, { children: _jsxs(Box, { direction: 'row', spacing: 24, align: 'center', children: [iconUrl ? (_jsx("div", { children: _jsx(IconBox, { src: iconUrl, alt: '' }) })) : null, _jsx("div", { id: 'dw-box', children: itemField?.map((item) => {
                        if (dataSource[item.children] === undefined) {
                            return null;
                        }
                        return (_jsx("div", { children: _jsx(CnTextEllipsis, { hasTooltip: true, line: 1, toolTipProps: {
                                    popupContainer: 'dw-box',
                                    v2: true,
                                    align: 'l',
                                }, children: dataSource[item.children] || '' }) }, item.children));
                    }) })] }) }));
};
export default Item;
