import { newRequest } from '@/api/request';
import { baseHost, handleResult } from '@/utils';
export class CardService {
    /**
     * 查询卡片配置
     * @param request
     * @returns
     */
    static async getConfigInfo(id) {
        const resp = handleResult(await newRequest({
            url: `/workbench/widget/queryById?id=${id}`,
        }));
        return resp;
    }
    /**
     * 新增卡片配置
     * @param data
     * @returns
     */
    static async addConfigInfo(data) {
        const resp = handleResult(await newRequest({
            url: '/workbench/widget/save',
            method: 'POST',
            data,
        }));
        return resp;
    }
    /**
     * 新增卡片配置
     * @param data
     * @returns
     */
    static async updateConfigInfo(data) {
        const resp = handleResult(await newRequest({
            url: '/workbench/widget/save',
            method: 'POST',
            data,
        }));
        return resp;
    }
    /**
     * 查询卡片下拉列表
     * @param id
     * @returns
     */
    static async queryOptionList(id) {
        const resp = handleResult(await newRequest({
            url: `${baseHost}/workbench/widget/option`,
            params: {
                id,
            },
        }));
        return resp;
    }
    /**
     * 删除卡片
     * @param id
     * @returns
     */
    static async delete(id) {
        const resp = handleResult(await newRequest({
            url: '/workbench/widget/delete',
            method: 'GET',
            params: {
                id,
            },
        }));
        return resp;
    }
}
