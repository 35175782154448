import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
const sizeMap = {
    xs: '0.5em',
    small: '0.75em',
    medium: '1em',
    large: '1.25em',
    xl: '1.5em',
    xxl: '1.75em',
    xxxl: '2em',
    inherit: 'inherit',
};
export const createIconComponent = (scriptUrl) => {
    const Icon = ({ type, size = 'medium', color, style, className, ...restProps }) => {
        const scriptLoaded = useIconLoader(scriptUrl);
        if (!scriptLoaded) {
            return null; // 或者可以返回一个占位符或者加载状态
        }
        // 根据size属性转换尺寸
        const fontSize = typeof size === 'number' ? `${size}px` : sizeMap[size];
        // 定义图标样式
        const iconStyle = {
            width: fontSize,
            height: fontSize,
            verticalAlign: '-0.15em',
            fill: color || 'currentColor',
            overflow: 'hidden',
            ...style, // 合并外部传入的style对象
        };
        // 使用type属性构建图标的引用链接
        const href = `#${type}`;
        // 合并额外的props和样式类名
        const svgClassName = `icon ${className || ''}`.trim();
        return (_jsx("svg", { className: svgClassName, "aria-hidden": "true", style: iconStyle, ...restProps, children: _jsx("use", { xlinkHref: href }) }));
    };
    return Icon;
};
export const useIconLoader = (scriptUrl) => {
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if (document.querySelector(`script[src="${scriptUrl}"]`)) {
            setLoaded(true);
            return;
        }
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.async = true;
        script.onload = () => setLoaded(true);
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [scriptUrl]);
    return loaded;
};
