/**
 * 数据转百分比
 * @param value
 * @param num
 */
export const handleToPercent = (value, num = 2) => typeof value === 'number' ? `${Number((value * 100).toFixed(num))}%` : '-%';
/**
 * 数组去重
 * @param data
 * @returns
 */
export function arrDeduplication(data) {
    if (Array.isArray(data)) {
        return [...new Set(data)];
    }
    return [];
}
/**
 * 数组求和
 * @param list
 */
export const arraySum = (list) => {
    if (Array.isArray(list) && list.length !== 0) {
        return list.reduce((a, b) => {
            const temp = Number(b);
            return a + (!Object.is(temp, NaN) ? temp : 0);
        }, 0);
    }
    return 0;
};
/**
 * 判断是否为一个有效数组（有值，并且长度大于 0）
 * @param data
 * @returns
 */
export const checkEntityArr = (data) => Array.isArray(data) && data.length > 0;
/**
 * 处理数据降序
 * @param data
 * @returns
 */
export const dataDescOrder = (data) => {
    if (Array.isArray(data)) {
        return data.sort((a, b) => b.value - a.value);
    }
    return data;
};
/**
 * 获取 X 轴配置
 * @param winChartProps
 * @returns
 */
export const getAxisOpt = (winChartProps) => {
    return {
        boundaryGap: true,
        axisTick: {
            alignWithLabel: true,
        },
        data: arrDeduplication(winChartProps.data?.map((item) => item.label)),
        axisLabel: {
            rotate: winChartProps.xAxisLabelRotate,
            formatter: (name) => {
                const configLength = winChartProps.xAxisLabelLength;
                if (typeof configLength === 'number' && name.length > configLength) {
                    return `${name.slice(0, configLength)}...`;
                }
                return name;
            },
        },
    };
};
/**
 * 根据 value 排序
 * @param arr
 * @param order
 * @returns
 */
export const sortArray = (arr, order) => {
    if (order === 'asc') {
        // 升序
        arr.sort((a, b) => {
            return a.value - b.value;
        });
    }
    else if (order === 'desc') {
        // 降序
        arr.sort((a, b) => {
            return b.value - a.value;
        });
    }
    return arr;
};
/**
 * 根据 label 排序
 * @param arr
 * @param sortedLabels
 * @returns
 */
export const sortArrayByLabel = (arr, sortedLabels) => {
    arr.sort((a, b) => {
        const indexA = sortedLabels.indexOf(a.label);
        const indexB = sortedLabels.indexOf(b.label);
        return indexA - indexB;
    });
    return arr;
};
/**
 * 处理数据排序
 * @param winChartProps
 * @returns
 */
export const handleSort = (winChartProps) => {
    const { data, extraData, sort } = winChartProps;
    if (data && sort) {
        // 1. 整体排序
        const sortedData = sortArray([...data], sort);
        const sortedLabels = arrDeduplication(sortedData.map((item) => item.label));
        // 2. 根据标签二次排序
        const finalSortData = sortArrayByLabel(sortedData, sortedLabels);
        const finalSortedExtraData = sortArrayByLabel([...(extraData ?? [])], sortedLabels);
        return {
            ...winChartProps,
            data: finalSortData,
            extraData: finalSortedExtraData,
        };
    }
    return winChartProps;
};
export function debounce(func, delay) {
    let timer;
    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(globalThis, args);
        }, delay);
    };
}
