import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
// import { withI18n } from '@alife/panda-i18n';
// import locale from 'src/locale';
import './index.scss';
function View({ src = '', width = '', align = 'left', }) {
    return (_jsx("div", { style: { textAlign: align }, children: _jsx("img", { width: width, src: src }) }));
}
const DWImage = View;
DWImage.displayName = 'DWImage';
export { DWImage };
