import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable array-callback-return */
/* eslint-disable no-prototype-builtins */
import React from 'react';
import styles from './index.module.scss';
export default function DWDetailItem(props) {
    const { value, readOnly } = props;
    // console.log('DWDetailItemProps ======>', props);
    // console.log('DWDetailItem value ======>', value);
    if (!readOnly) {
        return '编辑态不支持此类型';
    }
    // const value = [
    //   {
    //     text: '我是详情文案1',
    //     links: [
    //       {
    //         text: '链接1-1',
    //         href: 'https://www.cainiao.com/',
    //       },
    //       {
    //         text: '链接1-2',
    //         href: 'https://www.cainiao.com/',
    //       },
    //     ],
    //   },
    //   {
    //     text: '我是详情文案2',
    //     links: [
    //       {
    //         text: '链接2-1',
    //         href: 'https://www.cainiao.com/',
    //       },
    //       {
    //         text: '链接2-2',
    //         href: 'https://www.cainiao.com/',
    //       },
    //     ],
    //   },
    // ];
    let result = '-';
    let hasChild = false;
    let mapResult = '';
    if (Array.isArray(value) && value.length > 0) {
        mapResult = (_jsx("div", { className: styles.detail, children: value.map((item) => {
                if (item.hasOwnProperty('text') && item.hasOwnProperty('links')) {
                    hasChild = true;
                    return (_jsxs("p", { children: [_jsx("span", { children: item.text }), item.links.map((link) => {
                                return (_jsx("a", { target: '_blank', href: link.href, rel: 'noreferrer', children: link.text }));
                            })] }));
                }
                else {
                    return '';
                }
            }) }));
    }
    if (hasChild) {
        result = mapResult;
    }
    return result;
}
DWDetailItem.displayName = 'DWDetailItem';
export { DWDetailItem };
