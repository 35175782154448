/**
 * 页面操作类型
 */
export var OperateType;
(function (OperateType) {
    /**
     * 默认
     */
    OperateType["NONE"] = "none";
    /**
     * 添加
     */
    OperateType["ADD"] = "add";
    /**
     * 复制
     */
    OperateType["COPY"] = "copy";
    /**
     * 编辑
     */
    OperateType["EDIT"] = "edit";
    /**
     * 查看
     */
    OperateType["VIEW"] = "view";
    /**
     * 删除
     */
    OperateType["DELETE"] = "delete";
    /**
     * 反馈
     */
    OperateType["FEEDBACK"] = "feedback";
    /**
     * 追评
     */
    OperateType["REVIEW"] = "review";
    /**
     * 批量导入
     */
    OperateType["BATCH_ADD"] = "batch_add";
    /**
     * 批量操作
     */
    OperateType["BATCH_OPERATE"] = "batch_operate";
    /**
     * 授权
     */
    OperateType["AUTHORIZED"] = "authorized";
})(OperateType || (OperateType = {}));
export var WinCardChartType;
(function (WinCardChartType) {
    /**
     * 指标卡片
     */
    WinCardChartType["INDICATOR"] = "INDICATOR";
    /**
     * 指标加面积图
     */
    WinCardChartType["INDICATOR_AREA"] = "INDICATOR_AREA";
    /**
     * 指标加面积图
     */
    WinCardChartType["AREA_CHART"] = "AREA_CHART";
    /**
     * 折线图卡片
     */
    WinCardChartType["LINE_CHART"] = "LINE_CHART";
    /**
     * 柱状图
     */
    WinCardChartType["COLUMN_CHART"] = "COLUMN_CHART";
    /**
     * 堆叠柱状图
     */
    WinCardChartType["STACK_COLUMN_CHART"] = "STACK_COLUMN_CHART";
    /**
     * 条形图
     */
    WinCardChartType["BAR_CHART"] = "BAR_CHART";
    /**
     * 双轴图
     */
    WinCardChartType["DUAL_LINE_BAR_CHART"] = "DUAL_LINE_COLUMN_CHART";
    /**
     * 漏斗图
     */
    WinCardChartType["FUNNEL_CHART"] = "FUNNEL_CHART";
    /**
     * 饼图
     */
    WinCardChartType["PIE_CHART"] = "PIE_CHART";
    /**
     * 饼图(以指标项展示)
     */
    WinCardChartType["PIE_CHART_RESERVE"] = "PIE_CHART_RESERVE";
    /**
     * 环图
     */
    WinCardChartType["CYCLE_CHART"] = "CYCLE_CHART";
    /**
     * 雷达图
     */
    WinCardChartType["RADAR_CHART"] = "RADAR_CHART";
    /**
     * 雷达图(以指标项展示)
     */
    WinCardChartType["RADAR_CHART_RESERVE"] = "RADAR_CHART_RESERVE";
    /**
     * 自定义卡片
     */
    WinCardChartType["CUSTOM_CARD"] = "CUSTOM_CARD";
})(WinCardChartType || (WinCardChartType = {}));
/**
 * 事项类别
 */
export var MatterType;
(function (MatterType) {
    /**
     * 待办
     */
    MatterType["PENDING"] = "PENDING";
    /**
     * 我发出的
     */
    MatterType["CREATE"] = "CREATE";
    /**
     * 已办事项
     */
    MatterType["COMPLETE"] = "COMPLETE";
})(MatterType || (MatterType = {}));
