import dayjs from 'dayjs';
import { getRadomStr } from './str';
/**
 * 判断是否为一个有效数组（有值，并且长度大于 0）
 * @param data
 * @returns
 */
export const checkEntityArr = (data) => Array.isArray(data) && data.length > 0;
/**
 * 数组求和
 * @param list
 */
export const arraySum = (list) => {
    if (Array.isArray(list) && list.length !== 0) {
        return list.reduce((a, b) => {
            const temp = Number(b);
            return a + (!Object.is(temp, NaN) ? temp : 0);
        }, 0);
    }
    return 0;
};
/**
 * 数组去重
 * @param list
 * @returns
 */
export const arrayDeduplication = (list) => {
    if (Array.isArray(list)) {
        return [...new Set(list)];
    }
    return undefined;
};
/**
 * 合并数组
 * @param array1
 * @param array2
 * @returns
 */
export function mergeAndDeduplicateArrays(array1, array2) {
    // 合并两个数组
    const mergedArray = array1.concat(array2);
    // 创建一个新的Map对象用于去重和过滤
    const idToObjectMap = new Map();
    // 遍历合并后的数组，使用Map来去重和过滤id小于0的对象
    for (const item of mergedArray) {
        if (item.id > 0) {
            idToObjectMap.set(item.id, item);
        }
    }
    // 从Map对象获取去重和过滤后的对象数组，并按id升序排序
    const deduplicatedAndSortedArray = Array.from(idToObjectMap.values()).sort((a, b) => a.id - b.id);
    return deduplicatedAndSortedArray;
}
function timeDifference(date1, date2) {
    return date1.diff(date2, 'minute'); // 返回分钟差
}
function formatTimeAgo(time) {
    const now = dayjs();
    const diffMinutes = timeDifference(now, time);
    if (diffMinutes < 1) {
        return `刚刚`;
    }
    if (diffMinutes < 3) {
        return `${Math.floor(diffMinutes)}分钟前`;
    }
    // 其他时间格式化逻辑
    return time.format('YYYY.MM.DD HH:mm'); // 或者其他格式化方式
}
export function insertTimeMarkers(dialogs) {
    const newDialogs = [];
    let previousTime = null;
    for (const dialog of dialogs) {
        const currentTime = dialog.sendTime ? dayjs(dialog.sendTime) : dayjs();
        if (previousTime) {
            const diffMinutes = timeDifference(currentTime, previousTime);
            if (diffMinutes >= 1) {
                newDialogs.push({
                    id: getRadomStr(),
                    type: 'time-marker',
                    content: formatTimeAgo(currentTime),
                });
            }
        }
        newDialogs.push(dialog);
        previousTime = currentTime;
    }
    return newDialogs;
}
