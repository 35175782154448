import { jsx as _jsx } from "react/jsx-runtime";
import { Balloon } from '@alife/cn-ui';
export const HoverView = ({ followTrigger = false, content, style, cStyle, className, onClick, }) => {
    const { Tooltip } = Balloon;
    const intro = (_jsx("div", { onClick: onClick, className: className, style: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...cStyle,
        }, children: content }));
    return (_jsx(Tooltip, { trigger: intro, followTrigger: followTrigger, v2: true, align: 'br', style: style, children: content }));
};
