/**
 * 获取一个随机字符串
 * @param num
 * @returns
 */
export const getRadomStr = (num = 4) => Math.random()
    .toString(36)
    .slice(2, 2 + num);
/**
 * 判断是否是JSON字符串
 * @param str
 * @returns
 */
export function isJsonString(str) {
    if (!str)
        return false;
    try {
        JSON.parse(str);
    }
    catch (e) {
        return false;
    }
    return true;
}
