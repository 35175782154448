import { useState, useEffect } from 'react';
/**
 * 元素是否进入视口过
 * @param ref
 * @param options
 * @returns
 */
export const useHasIntersected = (ref, // 接受外部传入的 ref
options) => {
    const [hasIntersected, setHasIntersected] = useState(false);
    useEffect(() => {
        // 确保传入的 ref.current 不为空
        if (ref.current) {
            const observer = new IntersectionObserver((entries) => {
                // 当元素进入视口时，更新状态
                if (entries[0].isIntersecting) {
                    setHasIntersected(true);
                    // 如果只想监听一次，可以在这里取消观察
                    observer.unobserve(ref.current);
                }
            }, options);
            observer.observe(ref.current);
            return () => {
                observer.disconnect();
            };
        }
    }, [ref, options]);
    return hasIntersected;
};
