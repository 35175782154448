import { rgba } from 'polished';
import { COLOR_LIST } from './const';
import { arrDeduplication } from './tool';
/**
 * 雷达图
 * @param winChartProps
 * @returns
 */
export const getRadarOpt = (winChartProps) => {
    const typeList = arrDeduplication(winChartProps.data?.map((item) => winChartProps.reserveValueWithLabelType ? item.label : item.type));
    return {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            bottom: 0,
            type: 'scroll',
        },
        radar: {
            shape: 'circle',
            radius: '60%',
            indicator: arrDeduplication(winChartProps.data?.map((item) => winChartProps.reserveValueWithLabelType ? item.type : item.label)).map((name) => ({ name })),
            axisName: {
                color: '#5d677a',
            },
            splitArea: {
                areaStyle: {
                    color: ['transparent'],
                },
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(226, 229, 235, .3)',
                },
            },
            splitLine: {
                lineStyle: {
                    color: '#e2e5eb',
                    type: 'dashed',
                    dashOffset: 1.5,
                },
            },
        },
        series: [
            {
                type: 'radar',
                data: typeList.map((name, index) => ({
                    name,
                    value: winChartProps.data
                        ?.filter((item) => winChartProps.reserveValueWithLabelType
                        ? item.label
                        : item.type === name)
                        .map((item) => item.value),
                    areaStyle: {
                        color: rgba(COLOR_LIST[index], 0.2),
                    },
                    label: {
                        show: winChartProps.reserveValueWithLabelType,
                        formatter: (params) => {
                            return params.value?.toString() ?? '';
                        },
                    },
                })),
                emphasis: {
                    lineStyle: {
                        width: 4,
                    },
                },
            },
        ],
    };
};
