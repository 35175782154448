import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getHandleOpenLink } from 'src/utils';
import { css, styled } from 'styled-components';
import { UnitWrapper } from 'src/components/win-wrappers/card-wrapper';
export const IndicatorInfo = ({ label, value, unit, extraLabel, extraValue, extraValueColor, jumpUrl, isUnitWrap, ...args }) => {
    return (_jsxs("div", { ...args, children: [_jsx(LabelWrapper, { children: _jsxs("div", { "data-value": true, onClick: getHandleOpenLink(jumpUrl), style: {
                        ...(!!jumpUrl && {
                            cursor: 'pointer',
                        }),
                    }, children: [value, !isUnitWrap && _jsx(UnitWrapper, { children: unit })] }) }), isUnitWrap && _jsx(UnitWrapper, { children: unit })] }));
};
const LabelWrapper = styled.div `
  line-height: 1;
  margin-top: 12px;
  margin-bottom: 8px;

  [data-label] {
    width: 106px;
    font-size: 12px;
    margin-bottom: 8px;
    display: flex;

    ${(props) => !!props.unit &&
    css `
        &::after {
          content: '(${props.unit})';
          display: inline-block;
          color: #6d7a90;
        }
      `}
  }

  [data-value] {
    font-size: 30px;
    line-height: 16px;
    font-weight: 700;
    color: #000;
  }
`;
