import { CnMessage } from '@alife/cn-ui';
import { useEffect, useState } from 'react';
import { CardService } from 'src/services/CardService';
export const useCardOptionList = (id) => {
    const [state, setState] = useState([]);
    useEffect(() => {
        (async () => {
            try {
                if (id) {
                    const data = await CardService.queryOptionList(id);
                    setState(data);
                }
            }
            catch (error) {
                CnMessage(error.message);
            }
        })();
    }, [id]);
    return state;
};
