import { COLOR_LIST } from './const';
import { rgba } from 'polished';
import { arrDeduplication, getAxisOpt } from './tool';
import * as echarts from 'echarts';
/**
 * Mini 面积图
 * @param winChartProps
 * @returns
 */
export const getMiniAreaOpt = (winChartProps) => {
    const barTypeList = arrDeduplication(winChartProps.data?.map((item) => item.type));
    const { color } = winChartProps;
    return {
        color,
        grid: {
            top: 4,
            left: 0,
            right: 0,
            bottom: 0,
            containLabel: true,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#999',
                },
            },
        },
        legend: {
            show: false,
        },
        xAxis: {
            show: false,
            boundaryGap: false,
            data: arrDeduplication(winChartProps.data?.map((item) => item.label)),
        },
        yAxis: {
            show: false,
            min: winChartProps.yStart?.[0],
        },
        series: [
            ...barTypeList.map((name, index) => {
                const areaColor = color?.[index] ?? COLOR_LIST[index];
                return {
                    name,
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                        width: 2,
                    },
                    showSymbol: false,
                    areaStyle: {
                        opacity: 0.8,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: rgba(areaColor, 0.5),
                            },
                            {
                                offset: 1,
                                color: '#fff',
                            },
                        ]),
                    },
                    emphasis: {
                        focus: 'series',
                    },
                    data: winChartProps.data
                        ?.filter((item) => item.type === name)
                        .map((item) => item.value),
                };
            }),
        ],
    };
};
/**
 * 面积图
 * @param winChartProps
 * @returns
 */
export const getAreaOpt = (winChartProps) => {
    const barTypeList = arrDeduplication(winChartProps.data?.map((item) => item.type));
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#999',
                },
            },
        },
        legend: {
            bottom: 0,
            type: 'scroll',
        },
        xAxis: getAxisOpt(winChartProps),
        yAxis: {
            min: winChartProps.yStart?.[0],
        },
        series: [
            ...barTypeList.map((name, index) => {
                const areaColor = COLOR_LIST[index];
                return {
                    name,
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                        width: 2,
                    },
                    showSymbol: false,
                    areaStyle: {
                        opacity: 0.8,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: rgba(areaColor, 0.5),
                            },
                            {
                                offset: 1,
                                color: '#fff',
                            },
                        ]),
                    },
                    emphasis: {
                        focus: 'series',
                    },
                    data: winChartProps.data
                        ?.filter((item) => item.type === name)
                        .map((item) => item.value),
                };
            }),
        ],
    };
};
