import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IndicatorService } from 'src/services/IndicatorService';
import { getListLabel, handleAddQuery, handleNumberShowStr, unitList, } from '@/utils';
import { IndicatorInfo } from 'src/components/win-card/components/IndicatorInfo';
import { RequestWrapper } from 'src/components/win-wrappers/request-wrapper';
import { INDICATOR_DATA_BASE_URL } from '../../../utils/const';
import { WinChart, WinChartType } from 'src/components/win-chart';
import { getExtraLabel } from '../utils/tool';
import { CnBox } from '@alife/cn-ui';
export const IndicatorArea = ({ configData, orgLevelVal }) => {
    return (_jsx(RequestWrapper, { errorBoxHeight: 90, requestData: {
            url: handleAddQuery(INDICATOR_DATA_BASE_URL, {
                id: configData.id,
                dataAccVal: configData.dataAccCode,
                orgLevelVal,
            }),
        }, service: IndicatorService.getDataInfoList, children: (data) => {
            const curConfig = configData.indicatorConfig[0];
            const subTitle = curConfig.extra?.subTitle;
            return (_jsxs(CnBox, { direction: 'row', justify: 'space-between', children: [_jsx("div", { children: _jsx(IndicatorInfo, { jumpUrl: `/#/indicator-center/details?id=${curConfig.id}`, label: configData.name, unit: getListLabel(unitList, configData.indicatorConfig[0]?.unit), value: handleNumberShowStr(data.indicator?.value), extraLabel: getExtraLabel(subTitle) ?? data.indicator?.date, extraValue: data.indicator?.expected, isUnitWrap: true }) }), _jsx(WinChart, { color: configData.commonConfig.color, data: data.list0 ?? [], chartType: WinChartType.MINI_AREA, style: {
                            width: 160,
                            height: 84,
                        } })] }));
        } }));
};
