import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-prototype-builtins */
// 打印标签组件
import React, { useState } from 'react';
import { CnMessage, CnButton } from '@alife/cn-ui';
import requestMokelay from './print/request';
// import $i18n from '@alife/panda-i18n';
// import { transformRequestConfig } from 'src/utils/common';
import Print from './print/index';
export default function DWTablePrintTag(props) {
    const { children, buttonConfig = {}, disabled, selectedRowRecords, printKey, selectedRowKeys, printInfoInterface, printTemplateInterface, } = props;
    console.log('/////', props);
    const [visible, setVisible] = useState(false);
    const getPrintInfo = (list) => {
        return requestMokelay({
            method: 'POST',
            url: printInfoInterface,
            data: {
                param: list,
            },
        });
    };
    // const { options = {} } = buttonConfig;
    // const onOk = useCallback(async () => {}, []);
    // const [selectedPlan, setSelectedPlan] = useState({ ks: [], records: [] });
    const [prints, setPrints] = useState([]);
    const print = () => {
        // 判断选择项是否包含不可打印项
        // if (!record && selectedPlan.records && selectedPlan.records.length > 0) {
        //   const idx = selectedPlan.records.findIndex((item) => !item?.isCheck);
        //   if (idx > -1) {
        //     CnMessage.error(
        //       $i18n.get({
        //         id: 'ThereAreNonprintableDevicesInThe_1396886904',
        //         dm: '被选择记录中有不可打印设备，请查询可打印设备记录！',
        //       }),
        //     );
        //     return null;
        //   }
        // }
        if (Array.isArray(selectedRowRecords) && selectedRowRecords.length > 0) {
            setPrints(selectedRowRecords);
            setVisible(true);
        }
        else {
            // setPrints(selectedPlan.records);
            setPrints([]);
            CnMessage.warning('请您先选择需要打印的数据');
        }
    };
    // visible={props.visible}
    // onCancel={props.onCancel}
    // onClose={props.onClose}
    // onOk={() => {
    //   doSingleCloudPrint(list, props.printKey);
    //   props.onClose();
    // }}
    // cancelProps={{
    //   children: $i18n.get({ id: 'Close', dm: '关闭' }) }}
    // okProps={{
    //   children: loading ? $i18n.get({ id: 'PrintDataLoading', dm: '打印数据加载中' }) : $i18n.get({ id: 'ConfirmPrinting', dm: '确定打印' }),
    //   loading }}
    const { type: btType = 'normal' } = buttonConfig || {};
    return (_jsxs(_Fragment, { children: [_jsx(CnButton
            // {...props.buttonConfig}
            , { 
                // {...props.buttonConfig}
                type: btType, disabled: disabled, onClick: () => {
                    print();
                }, children: children || '打印标签' }), _jsx(Print, { visible: visible, title: `打印标签`, printKey: printKey, prints: prints, getPrintInfo: getPrintInfo, printTemplateInterface: printTemplateInterface, onCancel: () => {
                    setVisible(false);
                }, onClose: () => {
                    setVisible(false);
                } })] }));
}
DWTablePrintTag.displayName = 'DWTablePrintTag';
export { DWTablePrintTag };
