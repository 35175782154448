import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import $i18n from '@alife/panda-i18n';
import { useEffect, useRef, useState } from 'react';
import { checkEntityArr } from '@/utils';
import { MatterList } from './MatterList';
import { MatterType } from '@/types';
import { Box, CnPagination } from '@alife/cn-ui';
import { WorkbenchService } from 'src/services/WorkbenchService';
import { PanelTitleTab } from 'src/components/panel-title-tab';
import { RequestWrapper } from 'src/components/win-wrappers/request-wrapper';
const matterTitleList = [
    {
        label: $i18n.get({ id: 'TodoList', dm: '待办清单' }),
        value: MatterType.PENDING,
    },
    {
        label: $i18n.get({ id: 'ISentOut', dm: '我发出的' }),
        value: MatterType.CREATE,
    },
    {
        label: $i18n.get({ id: 'ItemsAlreadyHandled', dm: '已办事项' }),
        value: MatterType.COMPLETE,
    },
];
export const MatterContent = ({ style }) => {
    const [curTitleTab, setCurTitleTab] = useState(MatterType.PENDING);
    return (_jsxs("div", { style: style, children: [_jsx(PanelTitleTab, { value: curTitleTab, onChange: setCurTitleTab, dataList: matterTitleList }), matterTitleList.map((item) => (_jsx(MatterTable, { show: item.value === curTitleTab, workflowType: item.value, onChangeWorkflowType: setCurTitleTab }, item.value)))] }));
};
const MatterTable = ({ show, workflowType, onChangeWorkflowType, }) => {
    const [hasInitData, setHasInitData] = useState(false);
    const [paging, setPaging] = useState({
        currentPage: 1,
        pageSize: 5,
    });
    const dataRef = useRef();
    useEffect(() => {
        if (hasInitData && !checkEntityArr(dataRef.current)) {
            if (workflowType === MatterType.PENDING) {
                onChangeWorkflowType(MatterType.CREATE);
            }
            else if (workflowType === MatterType.CREATE) {
                onChangeWorkflowType(MatterType.COMPLETE);
            }
        }
    }, [hasInitData, onChangeWorkflowType, workflowType]);
    return (_jsx("div", { style: { display: show ? 'block' : 'none' }, children: _jsx(RequestWrapper, { initData: show, requestData: {
                workflowType,
                currentPage: paging.currentPage,
                pageSize: paging.pageSize,
            }, service: WorkbenchService.getMatterDataList, children: (data) => {
                setHasInitData(true);
                dataRef.current = data.tableData;
                return (_jsxs("div", { children: [_jsx(MatterList, { data: data.tableData, showHandle: workflowType === 'PENDING' }), _jsx(Box, { direction: 'row', justify: 'flex-end', children: _jsx(CnPagination, { size: 'small', shape: 'arrow-only', total: data.paging.totalCount, current: paging.currentPage, onChange: (currentPage) => setPaging({
                                    ...paging,
                                    currentPage,
                                }), pageSize: paging.pageSize }) })] }));
            } }) }));
};
