import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@alife/cn-ui';
const noContentImg = 'https://img.alicdn.com/imgextra/i4/O1CN01g0QafV1rIyRHX6HA9_!!6000000005609-55-tps-190-120.svg';
const noContentImgDark = 'https://img.alicdn.com/imgextra/i1/O1CN01A8j5NM26FVeLRKiQo_!!6000000007632-55-tps-160-104.svg';
const noAccessImg = 'https://img.alicdn.com/imgextra/i4/O1CN01oQNWzR1rY5L7fdHcH_!!6000000005642-55-tps-160-160.svg';
export const NoContent = ({ direction = 'row', message = '无内容', style, className, isDark, }) => {
    return (_jsxs(Box, { direction: direction, justify: 'center', align: 'center', spacing: 20, className: className, style: {
            transform: 'translateY(20px)',
            ...style,
        }, children: [_jsx("img", { alt: '', src: isDark ? noContentImgDark : noContentImg, style: {
                    width: 120,
                    height: 120,
                    objectFit: 'contain',
                } }), _jsx("span", { style: {
                    fontSize: 14,
                    fontWeight: 500,
                    color: '#6D7A90',
                }, children: message })] }));
};
export const NoAssess = ({ direction = 'row', message = '暂无权限', style, className, }) => {
    return (_jsxs(Box, { direction: direction, justify: 'center', align: 'center', spacing: 20, className: className, style: {
            ...style,
        }, children: [_jsx("img", { alt: '', src: noAccessImg, style: { width: 120, height: 120, objectFit: 'contain' } }), _jsx("span", { style: {
                    fontSize: 14,
                    fontWeight: 700,
                    color: '#6D7A90',
                }, children: message })] }));
};
