import { arrDeduplication, getAxisOpt } from './tool';
/**
 * 折线图
 * @param winChartProps
 * @returns
 */
export const getLineOpt = (winChartProps) => {
    const typeList = arrDeduplication(winChartProps.data?.map((item) => item.type));
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#999',
                },
            },
        },
        legend: {
            bottom: 0,
            type: 'scroll',
        },
        xAxis: getAxisOpt(winChartProps),
        yAxis: {
            min: winChartProps.yStart?.[0],
        },
        series: typeList.map((name) => {
            return {
                name,
                type: 'line',
                smooth: false,
                lineStyle: {
                    width: 2,
                },
                showSymbol: true,
                emphasis: {
                    focus: 'series',
                },
                data: winChartProps.data
                    ?.filter((item) => item.type === name)
                    .map((item) => item.value),
            };
        }),
    };
};
