import { jsx as _jsx } from "react/jsx-runtime";
import { ChartWrapper } from './components/chart-wrapper';
import { useRef, useEffect, useState } from 'react';
import { getEChartOptions } from './utils/getChartOptions';
import * as echarts from 'echarts';
import { commonOpt } from './utils/const';
import { debounce } from './utils/tool';
import light from './theme/win-light.json';
import dark from './theme/win-dark.json';
import deepMerge from 'deepmerge';
export * from './utils/const';
export * from './utils/type';
echarts.registerTheme('light', deepMerge(light, commonOpt));
echarts.registerTheme('dark', deepMerge(dark, commonOpt));
export const WinChart = ({ className, style, extraOption, theme = 'light', ...args }) => {
    const boxRef = useRef(null);
    const [eChart, setEChart] = useState();
    const argsStr = JSON.stringify(args);
    const extraOptionStr = JSON.stringify(extraOption ?? {});
    // 生成实例
    useEffect(() => {
        const eChart = echarts.init(boxRef.current, theme);
        setEChart(eChart);
        // 监听窗口变化
        const resize = debounce(eChart.resize, 500);
        globalThis.addEventListener('resize', () => {
            resize();
        });
    }, [theme]);
    // 设置配置
    useEffect(() => {
        if (eChart) {
            eChart.setOption(deepMerge(getEChartOptions(JSON.parse(argsStr)), JSON.parse(extraOptionStr)));
            setTimeout(() => {
                eChart.resize();
            }, 500);
        }
    }, [argsStr, eChart, extraOptionStr]);
    return _jsx(ChartWrapper, { ref: boxRef, className: className, style: style });
};
