import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { CnInput, CnAsyncSelect } from '@alife/cn-ui';
function ULDInput(props) {
    const { value, onChange } = props;
    const innerValue = Array.isArray(value) ? value : [];
    return (_jsx(CnInput, { maxLength: 5, hasClear: true, value: innerValue[1], onChange: (val) => {
            const _value = [...innerValue];
            _value[1] = val;
            onChange && onChange(_value);
        }, addonBefore: _jsx(CnAsyncSelect, { hasClear: true, showSearch: true, 
            // name='type'
            value: innerValue[0], onChange: (val) => {
                const _value = [...innerValue];
                _value[0] = val;
                onChange && onChange(_value);
            }, remoteUrl: '/getUldTypeList' }), addonAfter: _jsx(CnAsyncSelect, { hasClear: true, showSearch: true, 
            // name='owner'
            value: innerValue[2], onChange: (val) => {
                const _value = [...innerValue];
                _value[2] = val;
                onChange && onChange(_value);
            }, remoteUrl: '/queryCarrierInfoSelectOptionList' }) }));
}
ULDInput.displayName = 'ULDInput';
export { ULDInput };
