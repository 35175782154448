import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable array-callback-return */
/* eslint-disable no-prototype-builtins */
import React from 'react';
import { CnTextEllipsis } from '@alife/cn-ui';
export default function DWTableCellEllipsis(props) {
    const { value, line = 1 } = props;
    if (!value) {
        return '-';
    }
    if (typeof value !== 'string') {
        return _jsx("span", { style: { color: '#f00' }, children: "\u6570\u636E\u683C\u5F0F\u4E0D\u662F\u5B57\u7B26\u4E32" });
    }
    return _jsx(CnTextEllipsis, { line: line, children: value });
}
DWTableCellEllipsis.displayName = 'DWTableCellEllipsis';
export { DWTableCellEllipsis };
