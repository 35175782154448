import { dataDescOrder, handleToPercent } from './tool';
/**
 * 漏斗图
 * @param winChartProps
 * @returns
 */
export const getFunnelOpt = (winChartProps) => {
    const data = dataDescOrder(winChartProps.data)?.map((item) => ({
        value: item.value ?? 0,
        name: item.type,
    })) ?? [];
    const seriesConfig = {
        type: 'funnel',
        minSize: '20%',
        left: 10,
        top: 24,
        width: '76%',
        sort: 'descending',
        data,
    };
    return {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            bottom: 0,
            type: 'scroll',
        },
        series: [
            {
                ...seriesConfig,
                label: {
                    show: true,
                    position: 'outer',
                    formatter: '{c}',
                },
            },
            {
                ...seriesConfig,
                emphasis: {
                    label: {
                        fontSize: 20,
                    },
                },
                label: {
                    show: true,
                    position: 'inner',
                    color: '#fff',
                    formatter: (params) => {
                        const currentIndex = params.dataIndex;
                        if (currentIndex === 0) {
                            return '100%';
                        }
                        const prevValue = data[currentIndex - 1].value;
                        return handleToPercent(params.value / prevValue);
                    },
                },
            },
        ],
    };
};
