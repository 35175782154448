/**
 * 基础 request method, 基于 CNRequest 实现 (背后是 axios 实例).
 * 这里可以做一些业务的特殊定制, 比如说统一的埋点上报, 以及通用的网络错误处理.
 */
import cnRequest from '@alife/cn-request';
/**
 * boss内部未登入错误码
 */
export const bossBUCWithoutLoginErrorCodeList = ['CN10201812011'];
/**
 * boss外部未登入错误码（菜鸟登陆需要两次encodeURIComponent）
 */
export const bossCaiNiaoWithoutLoginErrorCodeList = ['CN10201912011'];
/**
 * boss内外部无权限错误码
 */
export const bossWithoutAccessErrCodeList = [
    'CN10201812021',
    'CN10201812022',
    'CN10201912021',
    'CN10201912022',
];
/**
 * 未登入错误码
 */
export const withoutLoginErrorCodeList = [
    'CN10829902001',
    '2000',
    '1000',
    ...bossBUCWithoutLoginErrorCodeList,
];
/**
 * 无权限错误码
 */
export const withoutAccessErrorCodeList = [
    'CN10829902004',
    ...bossWithoutAccessErrCodeList,
];
// 用于创建 axios 实例, 和 axios.create() 入参一致;
// 查看更多介绍 https://github.com/axios/axios#request-config
const axiosConfig = {
    withCredentials: true,
};
// 用于创建 CNRequest; 查看更多介绍 => https://npm.alibaba-inc.com/package/@alife/cn-request
const cnOptions = {
    // arms 的 trace 实例; 查看更多介绍 => https://yuque.antfin-inc.com/ali_arms/lav0sg/lh4stz
    turnOffLog: true,
    loginCallback: () => {
        // const curUrl = encodeURIComponent(window.location.href);
        // window.location.href = `https://cnlogin.cainiao.com/login?isNewLogin=true&showin=true&showdd=true&redirectURL=${curUrl}`;
    },
};
// request 是一个 axios 实例
const request = cnRequest(axiosConfig, cnOptions);
// 做一些基于业务场景的定制
request.interceptors.response.use((response) => {
    const obj = response.data;
    const { success, errorMsg, errorCode } = obj;
    if (withoutLoginErrorCodeList.includes(errorCode)) {
        const curHref = encodeURIComponent(window.parent.location.href);
        location.href = errorMsg + curHref;
    }
    if (bossCaiNiaoWithoutLoginErrorCodeList.includes(errorCode)) {
        const curHref = encodeURIComponent(encodeURIComponent(window.parent.location.href));
        location.href = errorMsg + curHref;
    }
    if (!success) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
            ...obj,
            message: errorMsg,
        });
    }
    return obj;
}, (err) => {
    console.error('network error', err);
    return Promise.reject(err);
});
export default request;
export const newRequest = async (config) => {
    const resp = (await request(config));
    return Promise.resolve(resp);
};
