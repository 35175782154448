import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
 * @author wb-zrq481890
 * @version 1.0.0
 * @Description index
 * @create 2023/7/3
 */
import React from 'react';
import { Pagination } from '@alife/cn-ui';
const NotificationContent = (props) => {
    const { paginationProps, total, onChange, children } = props;
    return (_jsxs("div", { children: [_jsx("div", { children: children }), _jsx("div", { style: { display: 'flex', justifyContent: 'end' }, children: _jsx(Pagination, { type: 'simple', onChange: onChange, total: total, current: paginationProps.current, pageSize: 1 }) })] }));
};
export default NotificationContent;
