import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useCallback } from 'react';
import { CnButton, Box } from '@alife/cn-ui';
import qs from 'qs';
const getParams = (linkParam = [], dataSource = {}) => {
    if (!Array.isArray(linkParam)) {
        return '';
    }
    const param = {};
    linkParam.forEach((item) => {
        if (dataSource[item.children] !== undefined) {
            param[item.children] = dataSource[item.children];
        }
    });
    return qs.stringify(param);
};
function LinkButton(props) {
    const { linkParam, link = '', isOpen, btnText, dataSource = {} } = props;
    const onClick = useCallback(() => {
        const params = getParams(linkParam, dataSource)?.length > 0
            ? `?${getParams(linkParam, dataSource)}`
            : '';
        if (isOpen) {
            window.open(`${link}${params}`);
            return null;
        }
        window.location.href = `${link}${params}`;
        return null;
    }, [dataSource, isOpen, link, linkParam]);
    const renderLintBtn = useMemo(() => {
        if (!link) {
            return null;
        }
        return (_jsx(Box, { direction: 'row', style: { paddingBottom: 20 }, children: _jsx(CnButton, { type: 'primary', onClick: onClick, style: { width: '100%' }, children: btnText }) }));
    }, [btnText, link, onClick]);
    return _jsx(_Fragment, { children: renderLintBtn });
}
export default LinkButton;
