import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-prototype-builtins */
import React, { useState, useCallback } from 'react';
import { CnDialog, CnOSSUpload, formilyReact, CnMessage } from '@alife/cn-ui';
import { request } from '@alife/cn-request';
import { transformRequestConfig } from 'src/utils/common';
const { useForm, useField } = formilyReact;
export default function DWArrayTableOcr(props) {
    const { children, buttonConfig = {}, _context = {} } = props;
    const [visible, setVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [file, setFile] = useState(null);
    const fieldInstance = useField();
    const formInstance = useForm();
    const { options = {} } = buttonConfig;
    const { uploadRequestConfig, ocrRequestConfig, ocrTitle = '发票' } = options;
    const { state } = _context;
    // console.log('props========>', props);
    // console.log('options========>', options);
    let ocrService = null;
    if (ocrRequestConfig && state) {
        ocrService = transformRequestConfig(ocrRequestConfig, state);
    }
    let uploadService = {};
    if (uploadRequestConfig && state) {
        uploadService = transformRequestConfig(uploadRequestConfig, state);
    }
    // console.log('ocrService=========>', ocrService);
    const onOk = useCallback(async () => {
        // console.log(
        //   'fieldInstance=====================================>',
        //   fieldInstance,
        // );
        // console.log(
        //   'formInstance=====================================>',
        //   formInstance,
        // );
        // console.log('file=====================================>', file);
        if (ocrService && file) {
            ocrService.data.file = file;
            const result = await request(ocrService);
            // console.log('result========>', result);
            const _data = result.data || {};
            if (_data.success) {
                if (_data.data) {
                    formInstance.setValuesIn(`${fieldInstance.path.segments[0]}[${fieldInstance.path.segments[1]}]`, {
                        ..._data.data,
                    });
                    setErrorVisible(false);
                    setVisible(false);
                    setErrorMsg('');
                }
                else {
                    CnMessage.error(`返回结果结构错误`);
                }
            }
            else {
                setErrorVisible(true);
                // setFile(null);
                setErrorMsg(_data.msg);
            }
        }
        else {
            CnMessage.error(`请先上传${ocrTitle}`);
        }
    }, [fieldInstance, formInstance, file, ocrService, ocrTitle]);
    const onFileChange = (fileList) => {
        // console.log('fileList=======>', fileList);
        if (fileList.length > 0) {
            setFile(fileList[0]);
        }
        else {
            setFile(null);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { onClick: () => {
                    setVisible(true);
                }, children: children }), _jsxs(CnDialog, { size: 'medium', title: `请上传${ocrTitle}`, visible: visible, onOk: onOk, onCancel: () => {
                    setErrorVisible(false);
                    setVisible(false);
                    setErrorMsg('');
                }, onClose: () => {
                    setErrorVisible(false);
                    setVisible(false);
                    setErrorMsg('');
                }, children: [_jsx(CnMessage, { type: 'error', shape: 'inline', visible: errorVisible, closeable: false, children: errorMsg }), _jsx(CnOSSUpload, { shape: 'dragger', limit: 1, service: uploadService, onChange: onFileChange })] })] }));
}
DWArrayTableOcr.displayName = 'DWArrayTableOcr';
export { DWArrayTableOcr };
