import { format } from 'd3';
/**
 * 数值转换（除，默认除 10000）
 * @param number
 * @param num
 * @returns
 */
export const divide = (number, num = 10000) => Number((number / num).toFixed(2));
/**
 * 根据单位转换数值
 * @param value
 * @param unit
 * @param withUnit
 * @returns
 */
export const convertValueWithUnit = (value, unit) => {
    if (value === undefined || value === null) {
        return '-';
    }
    if (unit && ['万元', '万'].includes(unit)) {
        return divide(value, 10000);
    }
    if (unit === '%') {
        return divide(value, 0.01);
    }
    return value;
};
/**
 * 数据转百分比
 * @param value
 * @param num
 */
export const handleToPercent = (value, num = 2) => typeof value === 'number' ? `${Number((value * 100).toFixed(num))}%` : '-%';
/**
 * 打点分割,保留两位小数
 * @param data
 * @param num 保留小数位数
 * @returns
 */
export const handleNumberShowStr = (data, num = 2) => {
    if (!data) {
        return data;
    }
    const fix = format(`,.${num}f`);
    return fix(Number(data));
};
/**
 * 转换成数字，保留两位小数
 * @param data
 * @param num 保留小数数位
 * @returns
 */
export const handleNumber = (data, num = 2) => {
    const fix = format(`.${num}f`);
    return Number(fix(Number(data)));
};
