(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@alife/cn-ui"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@alife/cn-ui", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["CN_UI_DWORK_COMMON"] = factory(require("react"), require("@alife/cn-ui"), require("react-dom"));
	else
		root["CN_UI_DWORK_COMMON"] = factory(root["React"], root["CNUI"], root["ReactDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__29__) {
return 