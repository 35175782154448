import { Select } from '@alife/cn-ui';
import { styled } from 'styled-components';
export const SelectDecoration = styled(Select).attrs({
    size: 'small',
    showSearch: true,
    style: {
        width: 130,
    },
    popupStyle: {
        width: 200,
    },
}) `
  transform: translateY(-4px);

  span {
    background: transparent;
  }
`;
export const IndicatorSelectDecoration = styled(Select).attrs({
    size: 'small',
    showSearch: true,
    style: {
        width: 100,
    },
    popupStyle: {
        width: 200,
    },
}) `
  span {
    background: transparent;
  }
`;
