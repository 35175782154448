import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable prefer-destructuring */
/* eslint-disable eqeqeq */
import React from 'react';
function View(props) {
    const { value, colors = [] } = props;
    let color = '';
    // 兼容 form 和 table
    // if (!colors) {
    //   colors = [];
    //   if (
    //     props.cellConfig &&
    //     props.cellConfig.options &&
    //     props.cellConfig.options.colors
    //   ) {
    //     colors = props.cellConfig.options.colors;
    //   }
    // }
    colors.forEach((item) => {
        if (item.val == value) {
            color = item.color;
        }
    });
    return _jsx("span", { style: { color }, children: value });
}
const DWTableStatusColor = View;
DWTableStatusColor.displayName = 'DWTableStatusColor';
export { DWTableStatusColor };
