import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import $i18n from '@alife/panda-i18n';
import { IndicatorService } from 'src/services/IndicatorService';
import { IndicatorArea } from './components/IndicatorArea';
import { RequestWrapper } from '../win-wrappers/request-wrapper';
import { CardTitleWrapper, CardWrapper } from '../win-wrappers/card-wrapper';
import { WinCardChartType } from 'src/types/enum';
import { INDICATOR_CONFIG_BASE_URL } from '../../utils/const';
import { MatterContent } from './components/MatterContent';
import { Indicator } from './components/Indicator';
import { ChartContent } from './components/ChartContent';
import { CnBox, CnDropdown, CnMenu, CnMenuItem, Icon } from '@alife/cn-ui';
import { IndicatorSelectDecoration, SelectDecoration, } from './components/SelectDecoration';
import { checkEntityArr, getHandleOpenLink, getListLabel, unitList, } from 'src/utils';
import { useContext, useEffect, useRef, useState } from 'react';
import { HoverView } from '../hover-view';
import { useCardOptionList } from 'src/hooks/useCardOptionList';
import { VisionUserConfigContext } from '../VisionUserConfigContext';
import { WinChartType } from '../win-chart';
import { WinRankContent } from './components/WinRankContent';
import { useHasIntersected } from '@/hooks/useHasIntersected';
export default function WinCard({ id, config, style }) {
    if (config) {
        return _jsx(ShowCard, { configData: config, style: style });
    }
    return (_jsx(RequestWrapper, { requestData: { url: `${INDICATOR_CONFIG_BASE_URL}?id=${id}` }, service: IndicatorService.getConfigInfo, style: {
            width: '100%',
            height: '100%',
            ...style,
        }, children: (configData) => {
            return (_jsx(ShowCard, { configData: configData, style: { height: configData.colHeight * 128 } }));
        } }));
}
const ShowCard = ({ configData, style, }) => {
    const wrapperRef = useRef(null);
    const hasIntersected = useHasIntersected(wrapperRef);
    const orgLevelList = useCardOptionList(hasIntersected && configData.commonConfig.orgLevel
        ? configData.id
        : undefined);
    const [curOrgLevelValue, setCurOrgLevelValue] = useState();
    const isSingleIndicator = configData.chartType === WinCardChartType.INDICATOR;
    const hasOrgLevel = !!configData.commonConfig.orgLevel;
    const isCommonTitle = ![WinCardChartType.CUSTOM_CARD].includes(configData.chartType);
    const jumpUrl = configData.detailUrl;
    const menuItems = [
        ...(jumpUrl
            ? [
                _jsx(CnMenuItem, { onClick: getHandleOpenLink(jumpUrl), children: $i18n.get({ id: 'ViewDetails', dm: '查看详情' }) }, 'jump'),
            ]
            : []),
    ];
    const visionUserConfig = useContext(VisionUserConfigContext);
    const hasInitOrgLevelRef = useRef(false);
    useEffect(() => {
        if (orgLevelList.length !== 0) {
            if (!hasInitOrgLevelRef.current) {
                // 第一次赋值
                setCurOrgLevelValue(orgLevelList[0].value);
                hasInitOrgLevelRef.current = true;
            }
        }
    }, [configData.commonConfig.orgLevel, orgLevelList, visionUserConfig]);
    // 单位
    const unit = getListLabel(unitList, configData.indicatorConfig[0]?.unit);
    return (_jsxs(CardWrapper, { ref: wrapperRef, style: style, children: [isCommonTitle && (_jsxs(CnBox, { direction: 'row', justify: 'space-between', style: { height: 20 }, children: [_jsx(CardTitleWrapper, { "data-card-id": configData.id, style: {
                            maxWidth: isSingleIndicator || !hasOrgLevel ? '80%' : '45%',
                            position: 'relative',
                            ...([
                                WinCardChartType.INDICATOR,
                                WinCardChartType.INDICATOR_AREA,
                            ].includes(configData.chartType) && {
                                fontSize: '12px',
                                fontWeight: 'normal',
                            }),
                        }, children: _jsx(HoverView, { content: configData.name }) }), _jsxs(CnBox, { direction: 'row', align: 'center', children: [hasOrgLevel && !isSingleIndicator && (_jsx(SelectDecoration, { value: curOrgLevelValue, dataSource: orgLevelList, onChange: setCurOrgLevelValue })), checkEntityArr(menuItems) && (_jsx("div", { style: { transform: 'translate(8px, -4px) rotate(0.25turn)' }, children: _jsx(CnDropdown, { trigger: _jsx(Icon, { type: 'ellipsis', style: { cursor: 'pointer' } }), children: _jsx(CnMenu, { style: { width: 15 }, children: menuItems }) }) }))] })] })), getShowContent({
                configData,
                hasOrgLevel,
                orgLevelVal: curOrgLevelValue,
                unit,
            }), isSingleIndicator && (_jsx(CnBox, { spacing: 8, children: hasOrgLevel && (_jsx(IndicatorSelectDecoration, { value: curOrgLevelValue, dataSource: orgLevelList, onChange: setCurOrgLevelValue })) }))] }));
};
const getShowContent = ({ configData, hasOrgLevel, orgLevelVal, unit, }) => {
    const data = {
        configData,
        orgLevelVal,
        unit,
    };
    if (hasOrgLevel && !orgLevelVal) {
        return null;
    }
    switch (configData.chartType) {
        case WinCardChartType.INDICATOR:
            return _jsx(Indicator, { ...data });
        case WinCardChartType.INDICATOR_AREA:
            return _jsx(IndicatorArea, { ...data });
        case WinCardChartType.DUAL_LINE_BAR_CHART:
            return (_jsx(ChartContent, { winChartType: WinChartType.DUAL_LINE_BAR, ...data }));
        case WinCardChartType.AREA_CHART:
            return _jsx(ChartContent, { winChartType: WinChartType.AREA, ...data });
        case WinCardChartType.LINE_CHART:
            return _jsx(ChartContent, { winChartType: WinChartType.LINE, ...data });
        case WinCardChartType.COLUMN_CHART:
            return _jsx(ChartContent, { winChartType: WinChartType.COLUMN, ...data });
        case WinCardChartType.STACK_COLUMN_CHART:
            return (_jsx(ChartContent, { winChartType: WinChartType.STACK_COLUMN, ...data }));
        case WinCardChartType.FUNNEL_CHART:
            return _jsx(ChartContent, { winChartType: WinChartType.FUNNEL, ...data });
        case WinCardChartType.BAR_CHART:
            return _jsx(ChartContent, { winChartType: WinChartType.BAR, ...data });
        case WinCardChartType.PIE_CHART:
            return _jsx(ChartContent, { winChartType: WinChartType.PIE, ...data });
        case WinCardChartType.PIE_CHART_RESERVE:
            return (_jsx(ChartContent, { reserveValueWithLabelType: true, winChartType: WinChartType.PIE, ...data }));
        case WinCardChartType.CYCLE_CHART:
            return _jsx(ChartContent, { winChartType: WinChartType.CYCLE, ...data });
        case WinCardChartType.RADAR_CHART:
            return _jsx(ChartContent, { winChartType: WinChartType.RADAR, ...data });
        case WinCardChartType.RADAR_CHART_RESERVE:
            return (_jsx(ChartContent, { reserveValueWithLabelType: true, winChartType: WinChartType.RADAR, ...data }));
        case WinCardChartType.CUSTOM_CARD:
            return getCustomCardContent(configData.name);
        default:
            return undefined;
    }
};
const getCustomCardContent = (name) => {
    switch (name) {
        case 'WIN排行榜':
            return _jsx(WinRankContent, {});
        case '工作台待办':
            return _jsx(MatterContent, {});
        default:
            return (_jsx("div", { children: $i18n.get({
                    id: 'TheCustomSliceWasNotFoundPleaseC_1572163781',
                    dm: '未找到该自定义该片，请检查名称是否正确',
                }) }));
    }
};
WinCard.displayName = 'WinCard';
export { WinCard };
