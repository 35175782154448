import { arrDeduplication } from './tool';
/**
 * 条形图
 * @param winChartProps
 * @returns
 */
export const getBarOpt = (winChartProps) => {
    const barTypeList = arrDeduplication(winChartProps.data?.map((item) => item.type));
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#999',
                },
            },
        },
        legend: {
            bottom: 0,
            type: 'scroll',
        },
        xAxis: {},
        yAxis: {
            data: arrDeduplication(winChartProps.data?.map((item) => item.label)),
            axisTick: {
                alignWithLabel: true,
            },
            axisPointer: {
                type: 'shadow',
            },
            min: winChartProps.yStart?.[0],
        },
        series: barTypeList.map((name) => {
            return {
                name,
                type: 'bar',
                barGap: '30%',
                barCategoryGap: '30%',
                barMaxWidth: 8,
                data: winChartProps.data
                    ?.filter((item) => item.type === name)
                    .map((item) => ({
                    value: item.value,
                    itemStyle: {
                        borderRadius: [0, 500, 500, 0],
                    },
                })),
            };
        }),
    };
};
