/**
 * 图表类型
 */
export var WinChartType;
(function (WinChartType) {
    /**
     * MINI面积图
     */
    WinChartType[WinChartType["MINI_AREA"] = 0] = "MINI_AREA";
    /**
     * 面积图
     */
    WinChartType[WinChartType["AREA"] = 1] = "AREA";
    /**
     * 双轴图
     */
    WinChartType[WinChartType["DUAL_LINE_BAR"] = 2] = "DUAL_LINE_BAR";
    /**
     * 柱状图
     */
    WinChartType[WinChartType["COLUMN"] = 3] = "COLUMN";
    /**
     * 堆叠柱状图
     */
    WinChartType[WinChartType["STACK_COLUMN"] = 4] = "STACK_COLUMN";
    /**
     * 折线图
     */
    WinChartType[WinChartType["LINE"] = 5] = "LINE";
    /**
     * 条形图
     */
    WinChartType[WinChartType["BAR"] = 6] = "BAR";
    /**
     * 漏斗图
     */
    WinChartType[WinChartType["FUNNEL"] = 7] = "FUNNEL";
    /**
     * 饼图
     */
    WinChartType[WinChartType["PIE"] = 8] = "PIE";
    /**
     * 环形图
     */
    WinChartType[WinChartType["CYCLE"] = 9] = "CYCLE";
    /**
     * 雷达图
     */
    WinChartType[WinChartType["RADAR"] = 10] = "RADAR";
})(WinChartType || (WinChartType = {}));
