import { arrDeduplication, getAxisOpt } from './tool';
/**
 * 双轴图（分组柱状 折线）
 * @param winChartProps
 * @returns
 */
export const getDualOpt = (winChartProps) => {
    const barTypeList = arrDeduplication(winChartProps.data?.map((item) => item.type));
    const lineTypeList = arrDeduplication(winChartProps.extraData?.map((item) => item.type));
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999',
                },
            },
        },
        legend: {
            bottom: 0,
            type: 'scroll',
        },
        xAxis: {
            ...getAxisOpt(winChartProps),
            axisPointer: {
                type: 'shadow',
            },
        },
        yAxis: [
            {
                alignTicks: true,
                min: winChartProps.yStart?.[0],
            },
            {
                alignTicks: true,
                min: winChartProps.yStart?.[1],
            },
        ],
        series: [
            ...barTypeList.map((name) => ({
                name,
                type: 'bar',
                barGap: '30%',
                barCategoryGap: '30%',
                barMaxWidth: 8,
                data: winChartProps.data
                    ?.filter((item) => item.type === name)
                    .map((item) => ({
                    value: item.value,
                    itemStyle: {
                        borderRadius: item.value > 0 ? [500, 500, 0, 0] : [0, 0, 500, 500],
                    },
                })),
            })),
            ...lineTypeList.map((name) => ({
                name,
                type: 'line',
                smooth: true,
                yAxisIndex: 1,
                showSymbol: false,
                data: winChartProps.extraData
                    ?.filter((item) => item.type === name)
                    .map((item) => item.value),
            })),
        ],
    };
};
