import { newRequest } from '@/api/request';
import { handleResult } from '@/utils';
export class WorkbenchService {
    /**
     * 获取大区列表
     * @returns
     */
    static async getRegionList() {
        const resp = handleResult(await newRequest({
            url: '/workbench/common/getRegionList?accessCode=WIN_WORKBENCH_REGION_MANAGEMENT',
        }));
        return resp;
    }
    /**
     * 获取热词数据
     * @returns
     */
    static async getHotDataList() {
        const resp = handleResult(await newRequest({
            url: '/workbench/suggest/recentAndHot',
        }));
        return resp;
    }
    /**
     * 获取推荐数据
     * @returns
     */
    static async getRecommendDataList(data) {
        const resp = handleResult(await newRequest({
            url: `/workbench/suggest?input=${data.keyWord ?? ''}`,
        }));
        return resp;
    }
    /**
     * 获取查询数据（分页）
     * @returns
     */
    static async getSearchDataListByType(data) {
        const resp = handleResult(await newRequest({
            url: `/workbench/searchByType?input=${data.keyWord ?? ''}${`&type=${data.type}`}${`&page=${data.page}`}${`&pageSize=${data.pageSize}`}`,
        }));
        return resp;
    }
    /**
     * 获取事项数据
     * @returns
     */
    static async getMatterDataList(data) {
        const resp = handleResult(await newRequest({
            url: '/workbench/common/queryWorkflowList',
            method: 'POST',
            data,
        }));
        return resp;
    }
    /**
     * 获取风险数据
     * @returns
     */
    static async getRiskData(data) {
        const resp = handleResult(await newRequest({
            url: `/workbench/risk/risk?dataAccVal=${data.dataAccVal}`,
            data,
        }));
        return resp.tableData ?? [];
    }
    /**
     * 获取隐患整改情况数据
     * @returns
     */
    static async getHazardData(data) {
        const resp = handleResult(await newRequest({
            url: `/workbench/risk/hiddenDanger?dataAccVal=${data.dataAccVal}`,
        }));
        return resp.tableData ?? [];
    }
    /**
     * 获取快捷菜单
     * @returns
     */
    static async getCommonFunctionsList() {
        const resp = handleResult(await newRequest({
            url: '/workbench/common/getCommonMenu',
        }));
        return resp;
    }
    /**
     * 获取快捷菜单
     * @returns
     */
    static async getCommonFunctionsListNew() {
        const resp = handleResult(await newRequest({
            url: '/menu/queryUserMenu',
            method: 'POST',
            data: {
                startMenuCode: 'VISION_MENU',
                productCodes: ['VISION'],
            },
        }));
        return resp;
    }
    /**
     * 获取工作台角色列表
     * @returns
     */
    static async getWorkbenchRoleList() {
        const resp = handleResult(await newRequest({
            url: '/menu/queryUserMenu',
            method: 'POST',
            data: {
                startMenuCode: 'dwork_vision_mobile',
                productCodes: ['VISION'],
            },
        }));
        return resp.map((item) => ({
            label: item.menuTitle,
            value: item.menuCode,
        }));
    }
    /**
     * 获取角色工作台选项
     * @returns
     */
    static async getWorkbenchOptions() {
        const resp = handleResult(await newRequest({
            url: '/wireless/common/querymobile',
            method: 'POST',
            data: {
                startMenuCode: 'dwork_vision_mobile',
                productCodes: ['VISION'],
            },
        }));
        return {
            roleList: resp.dworkMenuDTOList.map((item) => ({
                label: item.menuTitle,
                value: item.menuCode,
            })),
            ...resp.headDic,
            pendingList: resp.pendingInfoVOList.map((item) => ({
                ...item,
                ...(item.desc === '我的V信' && {
                    count: resp.countMap.unreadCount?.toString() ?? '0',
                }),
            })),
            noticeList: resp.dworkNoticeDTOTable.tableData ?? [],
        };
    }
    /**
     * 查询用户头像
     * @returns
     */
    static async queryUserAvatar() {
        const resp = handleResult(await newRequest({
            url: '/wireless/common/queryUserAvatar',
        }));
        return resp;
    }
    /**
     * 查询公告
     * @returns
     */
    static async queryNoticeBannerList() {
        const data = new FormData();
        data.append('topStatus', '1');
        data.append('noticeStatus', '2');
        data.append('currentPage', '1');
        data.append('pageSize', '10');
        const resp = handleResult(await newRequest({
            url: '/notice/query',
            method: 'POST',
            data,
        }));
        return resp.tableData;
    }
    /**
     * 查询公告
     * @returns
     */
    static async queryNotice({ currentPage = 1 }) {
        const data = new FormData();
        data.append('noticeStatus', '2');
        data.append('currentPage', currentPage.toString());
        data.append('pageSize', '10');
        const resp = handleResult(await newRequest({
            url: '/notice/query',
            method: 'POST',
            data,
        }));
        return resp;
    }
    /**
     * 查询公告详情
     * @param id
     * @returns
     */
    static async queryNoticeInfo(id) {
        const data = new FormData();
        data.append('id', id.toString());
        const resp = handleResult(await newRequest({
            url: '/notice/queryDetail',
            method: 'POST',
            data,
        }));
        return resp;
    }
    /**
     * 事项中心
     * @returns
     */
    static async queryPendingList() {
        const resp = handleResult(await newRequest({
            url: '/wireless/common/queryPendingCount',
        }));
        return resp;
    }
    /**
     * 查询红黑榜单
     * @returns
     */
    static async queryWinRankList() {
        const resp = handleResult(await newRequest({
            url: '/workbench/common/queryWinRankList',
        }));
        return resp;
    }
    /**
     * 查询简报信息
     * @returns
     */
    static async queryVReportInfo() {
        const resp = handleResult(await newRequest({
            url: '/message/weekly',
        }));
        return resp.extend;
    }
    /**
     * 查询简报未读信息数量
     * @returns
     */
    static async queryVReportMessageCount() {
        const resp = handleResult(await newRequest({
            url: '/console/message/countMessage',
        }));
        return resp;
    }
}
