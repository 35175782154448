export * from './const';
export * from './str';
export * from './arr';
export * from './map';
export * from './number';
/**
 * 过滤接口返回内容
 * @param {*} res
 * @returns
 */
export function handleResult({ success, data, errorMsg }) {
    if (!success) {
        throw new Error(errorMsg);
    }
    return data;
}
/**
 * 拼接 Query
 * @param url
 * @param searchObj
 * @returns
 */
export const handleAddQuery = (url, searchObj) => {
    const query = Object.entries(searchObj)
        .filter((item) => ![null, undefined].includes(item[1]))
        .map((item) => item.join('='))
        .join('&');
    return url.includes('?') ? `${url}&${query}` : `${url}?${query}`;
};
/**
 * 获取外部链接跳转处理
 * @param url
 * @returns
 */
export const getHandleOpenLink = (url) => () => !!url && globalThis.open(url);
/**
 * 获取值列表值
 * @param list
 * @param value
 * @returns
 */
export function getListLabel(list, value) {
    return list.find((item) => item.value === value)?.label;
}
