import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useHasIntersected } from '@/hooks/useHasIntersected';
import { useCallback, useEffect, useRef, useState } from 'react';
import { withoutAccessErrorCodeList } from 'src/api/request';
import { styled } from 'styled-components';
var DataType;
(function (DataType) {
    DataType[DataType["NONE"] = 0] = "NONE";
    DataType[DataType["SUCCESS"] = 1] = "SUCCESS";
    DataType[DataType["ERROR"] = 2] = "ERROR";
    DataType[DataType["NO_ACCESS"] = 3] = "NO_ACCESS";
    DataType[DataType["NO_DATA"] = 4] = "NO_DATA";
})(DataType || (DataType = {}));
export function RequestWrapper({ initData = true, defaultValue, requestData, service, children, errorBoxHeight = 150, debounceWait, isInside, noEmptyBox, ...args }) {
    const [dataType, setDataType] = useState(DataType.NONE);
    const [data, setData] = useState(defaultValue);
    const requestDataStr = JSON.stringify(requestData);
    const ref = useRef(null);
    const shouldInit = useHasIntersected(ref);
    const handleGetData = useCallback(async () => {
        try {
            setDataType(DataType.NONE);
            setData(await service({
                ...requestData,
                requestId: undefined,
            }, isInside));
            setDataType(DataType.SUCCESS);
        }
        catch (error) {
            if (withoutAccessErrorCodeList.includes(error.errorCode)) {
                // 无权限
                setDataType(DataType.NO_ACCESS);
            }
            else {
                setDataType(DataType.ERROR);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestDataStr]);
    useEffect(() => {
        if (shouldInit && initData) {
            handleGetData();
        }
    }, [shouldInit, initData, handleGetData]);
    const { img, remark, btn } = dataTypeMap.get(dataType) ?? {};
    return (_jsxs(DataBoxWrapper, { ref: ref, ...args, children: [dataType === DataType.SUCCESS && !!data && children(data), !noEmptyBox &&
                [DataType.ERROR, DataType.NO_ACCESS, DataType.NO_ACCESS].includes(dataType) && (_jsxs(EmptyBox, { children: [_jsx("img", { "data-img": true, src: img, alt: '' }), _jsxs("div", { "data-msg": true, children: [_jsx("span", { "data-remark": true, children: remark }), _jsx("span", { "data-btn": true, onClick: handleGetData, children: btn })] })] }))] }));
}
const DataBoxWrapper = styled.div `
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;
const EmptyBox = styled.div `
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  [data-img] {
    width: 100%;
    height: 50%;
    margin-bottom: 12px;
    object-fit: contain;
  }

  [data-msg] {
    font-size: 12px;
    line-height: 1;

    [data-remark] {
      color: #6d7a90;
      margin-right: 8px;
    }

    [data-btn] {
      color: #3379ff;
      cursor: pointer;
    }
  }
`;
const dataTypeMap = new Map()
    .set(DataType.ERROR, {
    img: 'https://img.alicdn.com/imgextra/i4/O1CN01vg4MQE1e6WfWTdtx4_!!6000000003822-55-tps-190-120.svg',
    remark: '数据加载失败',
    btn: '刷新一下～',
})
    .set(DataType.NO_ACCESS, {
    img: 'https://img.alicdn.com/imgextra/i1/O1CN01Iz7oDf1IqrBik7NYy_!!6000000000945-55-tps-190-120.svg',
    remark: '你暂无权限',
    btn: '',
})
    .set(DataType.NO_DATA, {
    img: 'https://img.alicdn.com/imgextra/i4/O1CN01g0QafV1rIyRHX6HA9_!!6000000005609-55-tps-190-120.svg',
    remark: '暂无数据',
    btn: '',
});
