/**
 * My Vision 页面类型
 */
export var PageType;
(function (PageType) {
    /**
     * 工作台
     */
    PageType["WORKBENCH"] = "workbench";
    /**
     * 我的审批
     */
    PageType["MY_APPROVAL"] = "approval";
})(PageType || (PageType = {}));
/**
 * 审批类型
 */
export var ApprovalType;
(function (ApprovalType) {
    /**
     * 待我审批
     */
    ApprovalType["PENDING"] = "PENDING";
    /**
     * 我已审批
     */
    ApprovalType["COMPLETE"] = "COMPLETE";
})(ApprovalType || (ApprovalType = {}));
