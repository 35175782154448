import { jsx as _jsx } from "react/jsx-runtime";
import { createIconComponent } from './win-icon';
export const CustomIcon = createIconComponent('//at.alicdn.com/t/a/font_2948873_3jpgumax2i.js');
export const IconBox = ({ src, size = 12, onClick, show = true, style, ...args }) => (_jsx("img", { alt: '', src: src, onClick: (e) => {
        if (onClick) {
            e.stopPropagation();
            onClick();
        }
    }, ...args, style: {
        width: size,
        height: size,
        objectFit: 'contain',
        display: show ? 'inline-block' : 'none',
        ...(onClick && {
            cursor: 'pointer',
        }),
        ...style,
    } }));
