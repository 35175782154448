import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from 'styled-components';
const spacing = 24;
function Tab({ dataList = [], value, onChange, ...args }) {
    return (_jsx("div", { ...args, children: dataList.map((item, index) => (_jsx("div", { "data-tab-item": true, "data-tab-last": index === dataList.length - 1, "data-checked": item.value === value, onClick: () => onChange(item.value), children: item.label }, index))) }));
}
export const PanelTitleTab = styled(Tab) `
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 500px;
  line-height: 1;
  color: #5d677a;
  font-size: 14px;
  margin-bottom: 16px;
  [data-tab-item] {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s;
    position: relative;
    margin-right: ${spacing}px;
    z-index: 1;
    &[data-tab-last='true'] {
      margin-right: 0px;
    }

    &[data-checked='true'] {
      font-weight: 700;
      color: #272f3d;
    }
  }
`;
