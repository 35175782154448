import { arraySum, dataDescOrder, handleToPercent } from './tool';
/**
 * 饼图
 * @param winChartProps
 * @returns
 */
export const getPieOpt = (winChartProps) => {
    const total = arraySum(winChartProps.data?.map((item) => item.value));
    return {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            bottom: 0,
            type: 'scroll',
        },
        series: [
            {
                top: -30,
                type: 'pie',
                radius: '50%',
                data: dataDescOrder(winChartProps.data)?.map((item) => ({
                    value: item.value,
                    name: winChartProps.reserveValueWithLabelType
                        ? item.label
                        : item.type,
                })),
                label: {
                    show: true,
                    fontSize: 12,
                    color: 'rgba(0, 0, 0, 0.7)',
                    position: 'outside',
                    formatter: (data) => {
                        return handleToPercent(data.value / total);
                    },
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
            },
        ],
    };
};
/**
 * 环图
 * @param winChartProps
 * @returns
 */
export const getPieCycleOpt = (winChartProps) => {
    const total = arraySum(winChartProps.data?.map((item) => item.value));
    return {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            bottom: 0,
            type: 'scroll',
        },
        series: [
            {
                top: -30,
                type: 'pie',
                radius: ['60%', '48%'],
                data: dataDescOrder(winChartProps.data)?.map((item) => ({
                    value: item.value,
                    name: item.type,
                })),
                label: {
                    show: true,
                    fontSize: 12,
                    color: 'rgba(0, 0, 0, 0.7)',
                    position: 'outside',
                    formatter: (data) => {
                        return handleToPercent(data.value / total);
                    },
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
            },
        ],
        graphic: [
            {
                type: 'text',
                left: 'center',
                top: 76,
                style: {
                    text: Number(total.toFixed(2)).toString(),
                    fill: 'rgba(0, 0, 0, .7)',
                    fontSize: 28,
                    fontWeight: 'bold', // 文字粗细
                },
            },
            {
                type: 'text',
                left: 'center',
                top: 112,
                style: {
                    text: '总计',
                    fill: '#828B9E',
                    fontSize: 12,
                    fontWeight: 'bold', // 文字粗细
                },
            },
        ],
    };
};
