import { getCnEnv } from '@alife/cn-env';
const env = getCnEnv();
export const baseHost = {
    prod: 'https://vision.cainiao-inc.com',
    pre: 'https://vision.cainiao-inc.test',
    daily: 'https://pre-vision.cainiao-inc.com',
    test: 'https://pre-vision.cainiao-inc.com',
}[env];
/**
 * 查询卡片配置基路径
 */
export const INDICATOR_CONFIG_BASE_URL = `${baseHost}/workbench/widget/queryById`;
/**
 * 查询卡片数据基路径
 */
export const INDICATOR_DATA_BASE_URL = `${baseHost}/workbench/widget/data`;
export const DATA_PAGE_BASE_URL = '/data';
export const NEXT_PAGE_BASE_NAME = '/cone/next';
/**
 * 响应式断点
 */
export const RESPONSIVE_BREAKPOINT = '768px';
