import { styled, css } from 'styled-components';
export const CardWrapper = styled.div `
  ${(props) => props.$hidden &&
    css `
      display: none;
    `}

  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0px rgba(39, 46, 58, 0.12);
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(30px);

  ${(props) => css `
    background-color: ${props.theme.cardBackgroundColor};
  `};

  .cn-next-loading-wrap {
    width: 100% !important;
    height: 100% !important;
  }
`;
export const CardWrapperMobile = styled.div `
  ${(props) => props.$show &&
    css `
      display: none;
    `}

  width: 100%;
  height: 100%;
  position: relative;
  padding: 0.32rem;
  border-radius: 0.24rem;
  box-shadow: 0 0.02rem 0.04rem 0 rgba(39, 46, 58, 0.12);
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(30px);

  ${(props) => css `
    background-color: ${props.theme.cardBackgroundColor};
  `};

  .cn-next-loading-wrap {
    width: 100% !important;
    height: 100% !important;
  }
`;
export const CardTitleWrapper = styled.div `
  color: ${(props) => props.theme.color ?? '#000'};
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 8px;
  ${({ $checked = true }) => !$checked &&
    css `
      color: ${(props) => props.theme.color ?? '#6d7a90'};
    `}
`;
export const CardTitleWrapperMobile = styled.div `
  color: ${(props) => props.theme.color ?? '#272F3D'};
  font-weight: 600;
  font-size: 0.32rem;
  line-height: 1;
  margin-bottom: 0.2rem;

  ${({ $checked = true }) => !$checked &&
    css `
      color: ${(props) => props.theme.color ?? '#6d7a90'};
    `}
`;
export const IconWrapper = styled.div `
  width: 30px;
  height: 30px;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 2px 4px 0px rgba(39, 46, 58, 0.12);
  background: no-repeat center / 16px 16px url('${(props) => props.$src}');
  cursor: pointer;
  background-color: #fff;

  ${(props) => css `
    background-color: ${props.theme.iconBackgroundColor};
  `}
`;
export const UnitWrapper = styled.span `
  color: #6d7a90;
  font-size: 12px;
  padding-left: 5px;
  display: inline-block;
`;
