import { WinChartType } from './type';
import { getAreaOpt, getMiniAreaOpt } from './getAreaSpec';
import { getDualOpt } from './getDualSpec';
import { getPieCycleOpt, getPieOpt } from './getPieSpec';
import { getLineOpt } from './getLineSpec';
import { getColumnOpt, getColumnStackOpt } from './getColumnSpec';
import { getFunnelOpt } from './getFunnelSpec';
import { getBarOpt } from './getBarSpec';
import { handleSort } from './tool';
import { getRadarOpt } from './getRadarSpec';
/**
 * 获取图表配置
 * @param chartType
 * @returns
 */
export const getEChartOptions = (originWinChartProps) => {
    const winChartProps = handleSort(originWinChartProps);
    switch (winChartProps.chartType) {
        case WinChartType.MINI_AREA:
            return getMiniAreaOpt(winChartProps);
        case WinChartType.AREA:
            return getAreaOpt(winChartProps);
        case WinChartType.LINE:
            return getLineOpt(winChartProps);
        case WinChartType.COLUMN:
            return getColumnOpt(winChartProps);
        case WinChartType.STACK_COLUMN:
            return getColumnStackOpt(winChartProps);
        case WinChartType.BAR:
            return getBarOpt(winChartProps);
        case WinChartType.FUNNEL:
            return getFunnelOpt(winChartProps);
        case WinChartType.DUAL_LINE_BAR:
            return getDualOpt(winChartProps);
        case WinChartType.PIE:
            return getPieOpt(winChartProps);
        case WinChartType.CYCLE:
            return getPieCycleOpt(winChartProps);
        case WinChartType.RADAR:
            return getRadarOpt(winChartProps);
        default:
            return {};
    }
};
