import { jsx as _jsx } from "react/jsx-runtime";
import { IndicatorService } from 'src/services/IndicatorService';
import { handleAddQuery, INDICATOR_DATA_BASE_URL } from '@/utils';
import { IndicatorInfo } from 'src/components/win-card/components/IndicatorInfo';
import { RequestWrapper } from 'src/components/win-wrappers/request-wrapper';
import { getExtraLabel } from '../utils/tool';
export const Indicator = ({ configData, unit, orgLevelVal }) => {
    return (_jsx(RequestWrapper, { noEmptyBox: true, errorBoxHeight: 50, requestData: {
            url: handleAddQuery(INDICATOR_DATA_BASE_URL, {
                id: configData.id,
                dataAccVal: configData.dataAccCode,
                orgLevelVal,
            }),
        }, service: IndicatorService.getDataInfoList, style: {
            height: 'auto',
        }, children: (data) => {
            const curConfig = configData.indicatorConfig[0];
            const subTitle = curConfig.extra?.subTitle;
            return (_jsx(IndicatorInfo, { jumpUrl: `/#/indicator-center/details?id=${curConfig.id}`, label: configData.name, unit: unit, value: data.indicator?.value, extraLabel: getExtraLabel(subTitle) ?? data.indicator?.date, extraValue: data.indicator?.expected }));
        } }));
};
