/**
 * 色值
 */
export const COLOR_LIST = [
    '#3D84FF',
    '#00DCF0',
    '#FCBC26',
    '#00DB75',
    '#BDB8FF',
    '#40B4FF',
    '#FFA101',
    '#90ABE0',
    '#6EE67A',
    '#6B84FF',
    '#FA6B69',
];
/**
 * 通用配置
 */
export const commonOpt = {
    grid: {
        top: 24,
        left: 0,
        right: 12,
        bottom: 32,
        containLabel: true,
    },
    color: COLOR_LIST,
    legend: {
        itemWidth: 10,
        itemHeight: 10,
        bottom: 0,
        type: 'scroll',
        icon: 'circle',
    },
};
